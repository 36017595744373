import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderForm from "../../components/HeaderForm";
import "./style.scss";
import Loader from "../../components/Loader";
import { getData } from "../../api";
import { onboardingSteps } from "../../components/reports/dummyArray";

const ClientGustoOnboarding = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const [error, setError] = useState("");
  const [userData, setUserData] = useState({});

  const handleNavigateOnboarding = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await getData("gusto-start-onboarding", {});
      if (res.status === true) {
        navigate("/client-gusto-onboard-forms");
      } else {
        setError("Failed to start onboarding. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while starting onboarding.");
    } finally {
      setLoading(false);
    }
  };

  const handleGetCompanyData = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await getData("get-gusto-client-data", {});
      if (res.status === true) {
        setCompanyData(res.data);
        setUserData(res.user_data);
        if (res.data.welcome_page === 0) {
          navigate("/client-gusto-onboard-forms");
        }
      } else {
        setError("Failed to fetch company data. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while fetching company data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOnboardingSteps = () => {
    return onboardingSteps.map((step, index) => (
      <div className="onboarding-body-points-sub-wrapper" key={`step-${index}`}>
        <span className="span-cus-wrapper">{index + 1}</span>
        <p>
          <span>{step.title}:</span> {step.description}
        </p>
      </div>
    ));
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <HeaderForm />
          <div className="boarding-layout board-main-section">
            <div className="onboarding-intro-wrapper">
              <div className="onboarding-intro-header px-3">
                {companyData.logo && (
                  <img
                    src={`${process.env.REACT_APP_CAKE_PHP_BASE_URL}${companyData.logo}`}
                    alt="company-logo"
                  />
                )}
                <div className="onboarding-intro-header-content">
                  <h5>
                    Welcome to {companyData.legal_name || "Our Platform"},{" "}
                    {userData.full_name}
                  </h5>
                  <p>
                    Congratulations on taking your first steps into our{" "}
                    {companyData.legal_name || "platform"}. Here, you'll have
                    access to tools and resources to streamline your employment
                    experience.
                  </p>
                </div>
              </div>
              <div className="onboarding-intro-body">
                <div className="onboarding-body-title-content">
                  <h5>Key steps to get started</h5>
                  <p>
                    Complete your company onboarding process in{" "}
                    {onboardingSteps.length} steps.
                  </p>
                </div>
                <div className="onboarding-body-points-wrapper">
                  {renderOnboardingSteps()}
                </div>
              </div>
              <div
                onClick={handleNavigateOnboarding}
                className="onboarding-intro-footer"
              >
                <button>Start Onboarding</button>
              </div>
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClientGustoOnboarding;
