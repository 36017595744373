import React, { useState, useEffect } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import Buttons from "../../../../components/Form/Button";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import documentIcon from "../../../../assets/images/documentIcon.svg";
import {
  onlyCharacterWithSpaceAndDot,
  autocloseTiming,
} from "../../../../api/regex";
import { postData } from "../../../../api";

const SignDocumentDetails = ({
  row,
  setShowSignPage,
  getSignDocumentsList,
}) => {
  const documentData = row;
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [signDetailsObject, setSignDetailsObject] = useState({
    signature_text: "",
    acknowledge: "0",
  });
  const [errors, setErrors] = useState({});
  const [documentObject, setDocumentObject] = useState();

  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };

  useEffect(() => {
    getSignDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSignDocument = async () => {
    setLoading(true);
    let obj = {
      stage: documentData?.stage,
      uuid: documentData?.uuid,
    };
    try {
      const res = await postData("get-form-pdf-url", {}, obj);
      if (res?.status === true) {
        setDocumentObject(res?.data);
        setLoading(false);
      }
    } catch (err) {}
  };

  const getSignDocumentDownload = async () => {
    setLoading(true);
    let obj = {
      stage: documentData?.stage,
      uuid: documentData?.uuid,
    };
    try {
      const res = await postData("get-form-pdf-url", {}, obj);
      if (res?.status === true) {
        window.open(res?.data?.document_url, "_blank");
        setLoading(false);
      }
    } catch (err) {}
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setSignDetailsObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const handleAcknowledgeChange = (e) => {
    if (e.target.checked === true) {
      setSignDetailsObject((prev) => ({
        ...prev,
        acknowledge: "1",
      }));
    } else {
      setSignDetailsObject((prev) => ({
        ...prev,
        acknowledge: "0",
      }));
    }
  };

  const findFormErrors = () => {
    const { signature_text, acknowledge } = signDetailsObject;
    const newErrors = {};
    if (!signature_text || signature_text === "")
      newErrors.signature_text =
        "You can only sign the document if you enter the Signature text";
    else if (!signature_text?.match(onlyCharacterWithSpaceAndDot)) {
      newErrors.signature_text = "The signature name should be alphabetic";
    }
    if (
      !acknowledge ||
      acknowledge === "" ||
      acknowledge === null ||
      acknowledge === undefined ||
      acknowledge === "0"
    )
      newErrors.acknowledge = "Please acknowlege";
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      signDocument();
    }
  };

  const signDocument = async () => {
    setLoading(true);
    let data = {
      stage: documentData?.stage,
      ...signDetailsObject,
      ...documentData,
    };
    const res = await postData("sign-form", {}, data);
    if (res.status === true) {
      setLoading(false);
      setShowSignPage(false);
      getSignDocumentsList();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <Container fluid>
          <div className="paystub-view">
            <div className="sign-doc-view my-3">
              <Row className="justify-content-center">
                <Col md={12} className="sign-details-name mb-3">
                  <div className="my-2">{documentData?.title}</div>
                  <div>
                    <Button
                      className="sign-download-btn"
                      onClick={() => getSignDocumentDownload()}
                    >
                      <img src={documentIcon} alt="doc" className="me-2" />
                      Download document
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={8}>
                  <div className="sign-frame mb-4">
                    {isIframeLoading && (
                      <div className="iframe-loading">Loading...</div>
                    )}

                    <iframe
                      src={`${documentObject?.document_url}#toolbar=0`}
                      name="sign-details--iframe-view"
                      height="100%"
                      width="100%"
                      title="Iframe Example"
                      onLoad={handleIframeLoad}
                    ></iframe>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={8}>
                  {documentData?.requires_signing ? (
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      <Form.Group className="mb-3" controlId="signature_text">
                        <Form.Label>Signature text</Form.Label>
                        <Form.Control
                          type="text"
                          value={signDetailsObject.signature_text}
                          onChange={handleInput}
                          isInvalid={!!errors.signature_text}
                          name="signature_text"
                          className="sign-text"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.signature_text}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="acknowledge">
                        <Form.Check
                          label={
                            documentData?.stage === "company-forms"
                              ? "I am the signatory and I agree to sign electronically"
                              : "I agree to sign electronically"
                          }
                          name="acknowledge"
                          type="checkbox"
                          checked={
                            signDetailsObject.acknowledge === "1" ? true : false
                          }
                          value="2"
                          onChange={(e) => handleAcknowledgeChange(e)}
                          id="acknowledge"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.acknowledge}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="sign-doc-btn text-end mt-4">
                        <Buttons
                          type="button"
                          className="btn-outline-primary me-2"
                          onClick={() => setShowSignPage(false)}
                        >
                          Back
                        </Buttons>
                        <Buttons
                          type="submit"
                          disabled={loading || isIframeLoading}
                          loading={loading}
                          className="btn-primary text-white"
                        >
                          Sign
                        </Buttons>
                      </div>
                    </Form>
                  ) : (
                    <div className="sign-doc-btn text-end mt-4">
                      <Buttons
                        type="button"
                        className="btn-outline-primary me-2"
                        onClick={() => setShowSignPage(false)}
                      >
                        Back
                      </Buttons>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      )}
      {loading && <Loader />}
    </>
  );
};

export default SignDocumentDetails;
