import React, { useState } from "react";
import "./clientSummary.scss";
import { Row, Col } from "react-bootstrap";
import HeaderForm from "../../../components/HeaderForm";
import OnboardHeading from "../../../components/OnboardHeading";
import AccordionComp from "./AccordionComp";
import ClientSuccessModal from "./ClientSuccessModal";
import Button from "../../../components/Form/Button";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../../api/regex";

function ClientGustoSummary(props) {
  const {
    step,
    nextStep,
    clientObject,
    stateList,
    industryType,
    payFrequencyList,
    firstPayList,
    secondPayList,
    taxPayerType,
    taxFillingForm,
    titleList,
  } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [redirectionUrl, setRedirectionUrl] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await postData("gusto-cilent-finish-onboarding", {});
      setLoading(false);
      setShow(true);
      setTitle(res.title);
      setSubTitle(res.subtitle);
      setRedirectionUrl(res.emp_onboarding_url);
    } catch (e) {
      toast.error(e, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderForm />
      <div className="boarding-layout board-main-section onboarding-summary-wrapper">
        <Row>
          <Col>
            <OnboardHeading
              title="onboarding checklist"
              subtitle="Your company onboarding process is estimated to take 5 minutes."
              upperCaseTitle
            />
          </Col>
        </Row>
        <div className="onboarding-accordion-wrapper">
          <h5 className="onboarding-title-wrapper">View summary</h5>
          <AccordionComp
            step={step}
            nextStep={nextStep}
            clientObject={clientObject}
            stateList={stateList}
            industryType={industryType}
            payFrequencyList={payFrequencyList}
            firstPayList={firstPayList}
            secondPayList={secondPayList}
            taxPayerType={taxPayerType}
            taxFillingForm={taxFillingForm}
            titleList={titleList}
          />
          <div className="text-end summary-page-submit-wrapper">
            <Button
              type="submit"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
              className="btn-primary text-white"
            >
              submit
            </Button>
          </div>
        </div>
      </div>
      {show && (
        <ClientSuccessModal
          show={show}
          onClose={handleClose}
          title={title}
          subtitle={subtitle}
          redirectionUrl={redirectionUrl}
        />
      )}
    </>
  );
}

export default ClientGustoSummary;
