import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import ViewTaskModal from "./ViewTaskModal";
import ViewHandbookModal from "./ViewHandbookModal";
import ViewAcknowledgeModal from "./ViewAcknowledgeModal";
import { postData, getData } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { autocloseTiming } from "../../../api/regex";

const OnboardingsTask = ({
  nextStep,
  onboardObject,
  stateList,
  handleGetEmployeeData,
  summaryPage,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState();
  const [viewTaskShowModal, setViewTaskShowModal] = useState(false);
  const [viewAcknowledgeShowModal, setViewAcknowledgeShowModal] =
    useState(false);
  const [selectedAcknowledgeDetails, setSelectedAcknowledgeDetails] =
    useState();
  const [viewHandbookShowModal, setViewHandbookShowModal] = useState(false);
  const [employeeObject, setEmployeeObject] = useState({
    handbook: {},
    tasks: [],
    e_sign: "",
  });
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  useEffect(() => {
    if (onboardObject?.onboarding_tasks) {
      setEmployeeObject(onboardObject?.onboarding_tasks);
    }
  }, [onboardObject]);

  useEffect(() => {
    getClientStep();
  }, [!viewTaskShowModal, acknowledged]); // eslint-disable-line react-hooks/exhaustive-deps

  const getClientStep = async () => {
    setLoading(true);
    const res = await getData("employee_onboarding_steps", {});
    if (res.status === true) {
      localStorage.setItem("company_name", res?.data?.client_name);
      setLoading(false);
      setEmployeeObject(res?.data?.onboarding_tasks);
      if (res?.data?.onboarding_tasks?.handbook?.completion === 1) {
        setErrors({});
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  //View task
  const ViewTaskClick = (data) => {
    setSelectedTaskDetails(data);
    setViewTaskShowModal(true);
  };

  const viewTaskModalClose = () => {
    setViewTaskShowModal(false);
  };

  // handbook
  const ViewHandBookClick = () => {
    setViewHandbookShowModal(true);
  };

  const viewHandBookModalClose = () => {
    setViewHandbookShowModal(false);
  };

  //View Acknowledge
  const ViewAcknowledgeClick = (data) => {
    setSelectedAcknowledgeDetails(data);
    setViewAcknowledgeShowModal(true);
  };

  const viewAcknowledgeModalClose = () => {
    setViewAcknowledgeShowModal(false);
  };

  const acknowledgedShow = (data) => {
    setAcknowledged(data);
  };

  const findFormErrors = () => {
    const { handbook } = employeeObject;
    const newErrors = {};
    if (
      onboardObject?.role === "employee" ||
      onboardObject?.role === "client-admin" ||
      onboardObject?.role === "manager"
    ) {
      if (handbook?.completion === 0) {
        newErrors.Acknowledge = "Please sign the handbook.";
      }
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      onboard();
    }
  };

  const onboard = async () => {
    setLoading(true);
    let data = {
      type: "onboardtask_completed",
    };
    const res = await postData("gusto-employee-onboarding-task", {}, data);
    if (res.status === true) {
      setLoading(false);
      nextStep(res.next_steps);
      handleGetEmployeeData();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <>
          <div className="board-right board-tab-sections">
            <h5 className="mb-3">Onboarding task</h5>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              autoComplete="off"
              className="custom-form"
            >
              <Row>
                <Col md="12">
                  <h6 className="mb-3 onboard_task_title">Tasks</h6>
                </Col>
                {employeeObject?.tasks?.length !== 0 ? (
                  employeeObject.tasks.map((data, index) => (
                    <Col md="12" key={index}>
                      <Card className="task_card blue_bg_card my-2">
                        <Card.Body>
                          <div className="d-flex">
                            <div className="task_detail">
                              <p className="task_title">{data?.name}</p>
                              <p className="task_time">
                                Due date : {data?.due_date}{" "}
                              </p>
                            </div>
                            {summaryPage ? null : (
                              <div className="task_action text-end">
                                {data?.completion === 1 ? (
                                  <Button
                                    type="button"
                                    className="btn-success text-white success-btn"
                                    onClick={() => ViewTaskClick(data)}
                                  >
                                    Completed
                                  </Button>
                                ) : (
                                  <Button
                                    type="button"
                                    className="btn-primary text-white"
                                    onClick={() => ViewTaskClick(data)}
                                  >
                                    Complete task
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <div>
                    It seems that you do not have any assigned tasks. Please
                    proceed to next step.
                  </div>
                )}
              </Row>
              <Row
                className={`${
                  onboardObject?.role === "contractor" ? "d-none" : " d-block"
                }`}
              >
                <Col md="12">
                  <h6 className="mb-3 mt-3 onboard_task_title">Handbook </h6>
                </Col>
                {employeeObject?.handbook?.length !== 0 ? (
                  <>
                    <Col md="12">
                      <Card className="task_card white_bg_card">
                        <Card.Body>
                          <div className="d-flex align-items-center">
                            <div className="task_detail">
                              <p className="task_title">
                                {employeeObject?.handbook?.name}{" "}
                                <span className="asterisk">*</span>{" "}
                              </p>
                            </div>
                            <div className="task_action text-end">
                              {employeeObject?.handbook?.completion === 1 ? (
                                <Button
                                  type="button"
                                  className="btn-success text-white success-btn"
                                  onClick={() =>
                                    ViewAcknowledgeClick(
                                      employeeObject?.handbook
                                    )
                                  }
                                >
                                  Acknowledged
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className="btn-primary text-white"
                                  onClick={() => ViewHandBookClick()}
                                >
                                  Read & Acknowledge
                                </Button>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    {<div className="err-feedback">{errors.Acknowledge}</div>}
                  </>
                ) : (
                  <div>
                    It seems that you do not have any assigned handbook. Please
                    proceed to next step.
                  </div>
                )}
              </Row>
              {summaryPage ? null : (
                <div className="text-end mt-4">
                  <Button
                    type="button"
                    className="btn-outline-primary me-2"
                    onClick={() => nextStep("federal_tax")}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    className="btn-primary text-white"
                  >
                    Save & Continue
                  </Button>
                </div>
              )}
            </Form>
          </div>
          {viewTaskShowModal && (
            <ViewTaskModal
              show={viewTaskShowModal}
              onClose={viewTaskModalClose}
              selectedTaskDetails={selectedTaskDetails}
            />
          )}
          {viewHandbookShowModal && (
            <ViewHandbookModal
              show={viewHandbookShowModal}
              handleClose={viewHandBookModalClose}
              handbook={employeeObject?.handbook}
              eSign={employeeObject?.e_sign}
              acknowledgedShow={acknowledgedShow}
            />
          )}
          {viewAcknowledgeShowModal && (
            <ViewAcknowledgeModal
              show={viewAcknowledgeShowModal}
              onClose={viewAcknowledgeModalClose}
              selectedAcknowledgeDetails={selectedAcknowledgeDetails}
            />
          )}
        </>
      )}
      {loading && <Loader />}
    </>
  );
};

export default OnboardingsTask;
