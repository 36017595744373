import React, { useEffect, useState } from "react";
import "./employeeScreen.scss";
import Loader from "../../../components/Loader";
import HeaderForm from "../../../components/HeaderForm";
import { getData } from "../../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../../api/regex";

const EmployeeWelcomScreen = (props) => {
  const { employeeObject, handleNavigateOnboarding } = props;
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [error, setError] = useState("");

  const handleGetCompanyData = async () => {
    setLoading(true);
    try {
      const res = await getData("get-gusto-client-data", {});
      if (res.status === true) {
        setCompanyData(res.data);
      } else {
        setError("Failed to fetch company data. Please try again.");
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetCompanyData();
  }, []);

  const renderOnboardingSteps = () => {
    return employeeObject?.welcome_screen?.map((step, index) => (
      <div className="onboarding-body-points-sub-wrapper" key={`step-${index}`}>
        <span className="span-cus-wrapper">{index + 1}</span>
        <p>
          <span>{step.name}:</span> {step.desc}
        </p>
      </div>
    ));
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <HeaderForm />
          <div className="boarding-layout board-main-section">
            <div className="onboarding-intro-wrapper">
              <div className="onboarding-intro-header px-3">
                {companyData.logo && (
                  <img
                    src={`${process.env.REACT_APP_CAKE_PHP_BASE_URL}${companyData.logo}`}
                    alt="company-logo"
                  />
                )}
                <div className="onboarding-intro-header-content">
                  <h5>Welcome to {companyData.legal_name || "Our Platform"}</h5>
                  <p>
                    Congratulations on taking your first steps into our{" "}
                    {companyData.legal_name || "Our platform"}. Here, you'll
                    have access to tools and resources to streamline your
                    employment experience.
                  </p>
                </div>
              </div>
              <div className="onboarding-intro-body">
                <div className="onboarding-body-title-content">
                  <h5>Key steps to get started</h5>
                  {/* <p>
                    Complete your company onboarding process in{" "}
                    {onboardingSteps.length} steps.
                  </p> */}
                </div>
                <div className="onboarding-body-points-wrapper">
                  {renderOnboardingSteps()}
                </div>
              </div>
              <div
                onClick={handleNavigateOnboarding}
                className="onboarding-intro-footer"
              >
                <button>
                  {employeeObject?.offer_letter_screen === true
                    ? "Accept offer letter"
                    : "Start Onboarding"}
                </button>
              </div>
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeWelcomScreen;
