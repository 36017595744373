import React from "react";
import { Accordion } from "react-bootstrap";
import CompanyDetails from "./companyDetails";
import BankDetailsPayroll from "./bankDetailsPayroll";
import FederalTaxSetup from "./FederalTaxSetup";
import BankVerification from "./bankVerification";
import AddSignatory from "./AddSignatory";
import "./clientSummary.scss";
// import PlusIcon from "../../../assets/images/onboarding_icons/add.svg";
// import RemoveIcon from "../../../assets/images/onboarding_icons/remove.svg";
import EditIcon from "../../../assets/images/onboarding_icons/edit_square.svg";

function AccordionComp(props) {
  const {
    nextStep,
    clientObject,
    stateList,
    industryType,
    payFrequencyList,
    firstPayList,
    secondPayList,
    taxPayerType,
    taxFillingForm,
    titleList,
  } = props;
  const renderHeader = (title, step) => (
    <div className="onboarding-accordion-custom-header d-flex align-items-center justify-content-between">
      <span>{title}</span>
      <div className="header-icons">
        <img
          src={EditIcon}
          alt="Edit Icon"
          className="onboarding-accordion-edit-icon"
          onClick={() => nextStep(step)}
        />
      </div>
    </div>
  );

  return (
    <div>
      <Accordion
        defaultActiveKey={["0", "1", "2", "3", "4"]}
        alwaysOpen
        className="custom-accordion"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {renderHeader("Company Information", "add_address")}
          </Accordion.Header>
          <Accordion.Body>
            <CompanyDetails
              nextStep={nextStep}
              onboardObject={clientObject}
              stateList={stateList}
              industryType={industryType}
              summaryPage
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            {renderHeader("Bank Information", "bank_info")}
          </Accordion.Header>
          <Accordion.Body>
            <BankDetailsPayroll
              nextStep={nextStep}
              onboardObject={clientObject}
              payFrequencyList={payFrequencyList}
              firstPayList={firstPayList}
              secondPayList={secondPayList}
              summaryPage
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            {renderHeader("Federal Tax Setup", "federal_tax")}
          </Accordion.Header>
          <Accordion.Body>
            <FederalTaxSetup
              nextStep={nextStep}
              onboardObject={clientObject}
              taxPayerType={taxPayerType}
              taxFillingForm={taxFillingForm}
              summaryPage
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            {renderHeader("Payroll Verification", "bank_verification")}
          </Accordion.Header>
          <Accordion.Body>
            <BankVerification
              nextStep={nextStep}
              onboardObject={clientObject}
              summaryPage
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            {renderHeader("Add Signatory", "add_signatory")}
          </Accordion.Header>
          <Accordion.Body>
            <AddSignatory
              nextStep={nextStep}
              onboardObject={clientObject}
              stateList={stateList}
              titleList={titleList}
              summaryPage
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
export default AccordionComp;
