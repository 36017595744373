import React, { useState } from "react";
import "./employeeOnboardingSummary.scss";
import { Row, Col } from "react-bootstrap";
import HeaderForm from "../../../components/HeaderForm";
import OnboardHeading from "../../../components/OnboardHeading";
import AccordionComp from "./AccordionComp";
import Button from "../../../components/Form/Button";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../../api/regex";
import Loader from "../../../components/Loader";

function EmployeeOnboardingSummary(props) {
  const {
    step,
    nextStep,
    clientObject,
    stateList,
    industryType,
    payFrequencyList,
    firstPayList,
    secondPayList,
    taxPayerType,
    taxFillingForm,
    titleList,
    tabsList,
    relationshipList,
    handleGetEmployeeData,
    reloadCallback,
    empAgreeTabActive,
    federalList,
    signatorySubmit,
    eligibilityList,
    countryList,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    let data = { stage: "finish_onboarding" };
    const res = await postData("gusto-employee-status-update", {}, data);
    if (res.status === true) {
      setLoading(false);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      localStorage.setItem("company_name", res.company_name);
      if (res.auto_url) {
        window.open(res.auto_url, "_self");
      }
    } else {
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderForm />
          <div className="boarding-layout board-main-section onboarding-summary-wrapper">
            <Row>
              <Col>
                <OnboardHeading
                  title="onboarding checklist"
                  subtitle="Your company onboarding process is estimated to take 5 minutes."
                  upperCaseTitle
                />
              </Col>
            </Row>
            <div className="onboarding-accordion-wrapper">
              <h5 className="onboarding-title-wrapper">View summary</h5>
              <AccordionComp
                step={step}
                nextStep={nextStep}
                clientObject={clientObject}
                stateList={stateList}
                industryType={industryType}
                payFrequencyList={payFrequencyList}
                firstPayList={firstPayList}
                secondPayList={secondPayList}
                taxPayerType={taxPayerType}
                taxFillingForm={taxFillingForm}
                titleList={titleList}
                tabsList={tabsList}
                relationshipList={relationshipList}
                handleGetEmployeeData={handleGetEmployeeData}
                reloadCallback={reloadCallback}
                empAgreeTabActive={empAgreeTabActive}
                federalList={federalList}
                signatorySubmit={signatorySubmit}
                loading={loading}
                eligibilityList={eligibilityList}
                countryList={countryList}
              />
              <div className="text-end summary-page-submit-wrapper">
                <Button
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  onClick={handleSubmit}
                  className="btn-primary text-white"
                >
                  submit
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EmployeeOnboardingSummary;
