import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import {
  mustNumbers,
  ssnRegex,
  onlyCharacterWithSpace,
  emptySpace,
  characterWithCommaDotHyphenAndApos,
} from "../../../api/regex";
import {
  postData,
  socialSecurityNumberFormat,
  convertUTCToLocalDate,
} from "../../../api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../components/Loader";
import ContactInfomation from "./ContactInfomation";
// import moment from 'moment'

const PersonalInformation = ({
  nextStep,
  onboardObject,
  stateList,
  relationshipList,
  handleGetEmployeeData,
  summaryPage,
}) => {
  const focusDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeObject, setEmployeeObject] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    birthday: "",
    soc_sec_num: "",
    job_location_id: "",
    gender: "Male",
    contractor_type: "1",
  });
  const [errors, setErrors] = useState({});
  const [dateInputs, setDateInputs] = useState({
    birthday: null,
  });
  const [addressList, setAddressList] = useState([]);
  const currentDate = new Date();

  useEffect(() => {
    if (onboardObject?.personal_info) {
      setEmployeeObject(onboardObject?.personal_info);
      setAddressList(onboardObject?.client_job_address);
      if (onboardObject?.personal_info.birthday !== null) {
        setDateInputs({
          birthday: convertUTCToLocalDate(
            onboardObject?.personal_info?.birthday
          ),
        });
      } else {
        setDateInputs({ birthday: null });
      }
    } else if (onboardObject?.client_job_address) {
      setAddressList(onboardObject?.client_job_address);
    }
  }, [onboardObject]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEmployeeObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const handleGenderChange = (e) => {
    setEmployeeObject((prev) => ({
      ...prev,
      gender: e.target.value,
    }));
  };

  const findFormErrors = () => {
    const {
      first_name,
      preferred_name,
      middle_name,
      last_name,
      gender,
      birthday,
      soc_sec_num,
      job_location_id,
      contractor_type,
      business_name,
      federal_ein,
    } = employeeObject;
    const newErrors = {};
    let newSocialNumber = soc_sec_num?.replaceAll("-", "");
    let newFedNumber = federal_ein?.replaceAll("-", "");
    if (!first_name || first_name === "")
      newErrors.first_name = "Please enter your first name.";
    else if (first_name.length < 3) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (first_name.length > 70) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (!first_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.first_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    } else if (!first_name?.match(emptySpace)) {
      newErrors.first_name = "The first name should be alphabetic.";
    }
    if (preferred_name !== "" && preferred_name !== null) {
      if (preferred_name.length < 3) {
        newErrors.preferred_name =
          "The preferred name should be  3 to 70 characters long.";
      } else if (preferred_name.length > 70) {
        newErrors.preferred_name =
          "The preferred name should be  3 to 70 characters long.";
      } else if (!preferred_name?.match(characterWithCommaDotHyphenAndApos)) {
        newErrors.preferred_name =
          "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
      } else if (!preferred_name?.match(emptySpace)) {
        newErrors.preferred_name = "The preferred name should be alphabetic.";
      }
    }
    if (middle_name !== "" && middle_name !== null) {
      if (middle_name.length < 3) {
        newErrors.middle_name =
          "The middle name should be  3 to 50 characters long.";
      } else if (middle_name.length > 50) {
        newErrors.middle_name =
          "The middle name should be  3 to 50 characters long.";
      } else if (!middle_name?.match(characterWithCommaDotHyphenAndApos)) {
        newErrors.middle_name =
          "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
      } else if (!middle_name?.match(emptySpace)) {
        newErrors.middle_name = "The middle name should be alphabetic";
      }
    }
    if (!last_name || last_name === "")
      newErrors.last_name = "Please enter your last name.";
    // else if (last_name.length < 3) {
    //     newErrors.last_name = "The last name should be  3 to 70 characters long.";
    // } else if (last_name.length > 70) {
    //     newErrors.last_name = "The last name should be  3 to 70 characters long.";
    // }
    else if (!last_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.last_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    } else if (!last_name?.match(emptySpace)) {
      newErrors.last_name = "Please enter your last name.";
    }
    if (!gender || gender === "" || gender === null)
      newErrors.gender = "Please select gender.";
    if (!birthday || birthday === "" || birthday === null)
      newErrors.birthday = "Please enter your date of birth.";
    // if (onboardObject?.skip_gusto_payroll === 1) {
    else if (
      currentDate.getFullYear() - new Date(birthday).getFullYear() < 13 ||
      (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
        currentDate.getMonth() < new Date(birthday).getMonth()) ||
      (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
        currentDate.getMonth() === new Date(birthday).getMonth() &&
        currentDate.getDate() < new Date(birthday).getDate())
    ) {
      newErrors.birthday = "The age must be at least 13 years old.";
    }
    // }
    if (
      (onboardObject?.role === "contractor" &&
        employeeObject?.contractor_type?.toString() === "1") ||
      onboardObject?.role === "employee" ||
      onboardObject?.role === "client-admin" ||
      onboardObject?.role === "manager"
    ) {
      if (!newSocialNumber || newSocialNumber === "")
        newErrors.soc_sec_num = "Please enter your social security number.";
      else if (!newSocialNumber.toString()?.match(mustNumbers)) {
        newErrors.soc_sec_num = "The social security number should be numeric.";
      } else if (newSocialNumber.length !== 9) {
        newErrors.soc_sec_num =
          "The social security number should be 9 characters";
      } else if (!soc_sec_num.toString()?.match(ssnRegex)) {
        newErrors.soc_sec_num = "Please enter a valid  social security number";
      }
    }

    if (onboardObject?.role === "contractor") {
      if (
        !contractor_type ||
        contractor_type === "" ||
        contractor_type === null
      )
        newErrors.contractor_type = "Please select type.";
      if (employeeObject?.contractor_type?.toString() === "0") {
        if (!business_name || business_name === "")
          newErrors.business_name = "Please enter your business name.";
        else if (business_name.length < 5) {
          newErrors.business_name =
            "The business name should be  5 to 30 characters long";
        } else if (business_name.length > 30) {
          newErrors.business_name =
            "The business name should be  5 to 30 characters long";
        } else if (!business_name?.match(onlyCharacterWithSpace)) {
          newErrors.business_name = "The business name should be alphabetic";
        } else if (!business_name?.match(emptySpace)) {
          newErrors.business_name = "The business name should be alphabetic";
        }

        if (!newFedNumber || newFedNumber === "")
          newErrors.federal_ein = "Please enter your federal EIN number.";
        else if (!newFedNumber?.match(mustNumbers)) {
          newErrors.federal_ein = "The federal EIN number should be numbers";
        } else if (newFedNumber.length !== 9) {
          newErrors.federal_ein =
            "The federal EIN number should be 9 characters";
        }
      }
    }
    //
    // if (
    //   (onboardObject?.role === "employee" ||
    //     onboardObject?.role === "client-admin" ||
    //     onboardObject?.role === "manager") &&
    //   onboardObject?.skip_gusto_payroll !== 1
    // ) {
    // }
    if (onboardObject?.skip_gusto_payroll !== 1) {
      if (!job_location_id || job_location_id === "")
        newErrors.job_location_id = "Please enter your work address.";
    }
    return newErrors;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      personalInfo();
    }
  };

  const personalInfo = async () => {
    let data = {
      stage: "personal_info",
      ...employeeObject,
      skip_gusto_payroll: onboardObject?.skip_gusto_payroll
        ? onboardObject?.skip_gusto_payroll
        : 0,
    };

    setLoading(true);
    const res = await postData("employee_onboarding_steps", {}, data);
    if (res.status === true) {
      setLoading(false);
      setErrors({});
      nextStep(res.next_steps);
    } else {
      toast.error(res.message, { theme: "colored", autoClose: 5000 });
      setLoading(false);
    }
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      let val = formatDates(date);
      setEmployeeObject((prev) => ({
        ...prev,
        [name]: val,
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
      // setDateInputs((prev) => ({
      //     ...prev,
      //     [name]: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
      // }));
      if (!!errors[name])
        setErrors({
          ...errors,
          [name]: null,
        });
    }
  };

  const handleFocusDate = () => {
    const datepickerElement = focusDate.current;
    datepickerElement.setFocus(true);
  };

  const handleFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = socialSecurityNumberFormat(e.target.value);
    setEmployeeObject((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ""); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 9;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };

  const handleTypeChange = (e) => {
    setEmployeeObject((prev) => ({
      ...prev,
      contractor_type: e.target.value,
    }));
  };
  return (
    <>
      {!loading && (
        <div className="board-right board-tab-sections">
          <h5 className="mb-3">Personal Information</h5>

          <Form
            noValidate
            validated={validated}
            // onSubmit={handleSubmit}
            autoComplete="off"
            className="custom-form"
          >
            <Row>
              <Col
                md={12}
                className={`${
                  onboardObject?.role === "contractor" ? "d-block" : " d-none"
                }`}
              >
                <Form.Group className="mb-3" controlId="contractor_type">
                  <Form.Label className="me-2">Type</Form.Label>
                  <div className="type-contractor mb-2">
                    This information will be used for payments and on tax
                    documents, so double check that it’s accurate.
                  </div>
                  <div>
                    <Form.Check
                      inline
                      label="Individual"
                      name="contractor_type"
                      type="radio"
                      checked={
                        employeeObject?.contractor_type?.toString() === "1"
                          ? true
                          : false
                      }
                      value="1"
                      onChange={(e) => handleTypeChange(e)}
                      id="individual"
                      disabled={summaryPage}
                    />
                    <Form.Check
                      inline
                      label="Business"
                      name="contractor_type"
                      type="radio"
                      checked={
                        employeeObject?.contractor_type?.toString() === "0"
                          ? true
                          : false
                      }
                      value="0"
                      onChange={(e) => handleTypeChange(e)}
                      id="business"
                      disabled={summaryPage}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.contractor_type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="first_name">
                  <Form.Label>
                    First name <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={employeeObject.first_name}
                    onChange={handleInput}
                    isInvalid={!!errors.first_name}
                    name="first_name"
                    maxLength="70"
                    required
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="preferred_name">
                  <Form.Label>Preferred name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter preferred name"
                    value={employeeObject.preferred_name}
                    onChange={handleInput}
                    isInvalid={!!errors.preferred_name}
                    name="preferred_name"
                    maxLength="70"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.preferred_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="middle_name">
                  <Form.Label>Middle name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter middle name "
                    value={employeeObject.middle_name}
                    onChange={handleInput}
                    name="middle_name"
                    isInvalid={!!errors.middle_name}
                    maxLength="50"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.middle_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="last_name">
                  <Form.Label>
                    Last name <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    value={employeeObject.last_name}
                    onChange={handleInput}
                    isInvalid={!!errors.last_name}
                    name="last_name"
                    // maxLength="70"
                    required
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="gender">
                  <Form.Label>
                    Gender <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Male"
                      name="gender"
                      type="radio"
                      checked={employeeObject.gender === "Male" ? true : false}
                      value="Male"
                      onChange={(e) => handleGenderChange(e)}
                      required
                      id="Male"
                      disabled={summaryPage}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      name="gender"
                      type="radio"
                      checked={
                        employeeObject.gender === "Female" ? true : false
                      }
                      value="Female"
                      onChange={(e) => handleGenderChange(e)}
                      required
                      id="Female"
                      disabled={summaryPage}
                    />
                    {validated && employeeObject?.gender === null && (
                      <div className="err-feedback">{errors?.gender}</div>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group
                  className="position-relative mb-3 personal-date-picker"
                  controlId="birthday"
                >
                  <Form.Label>
                    Date of birth <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <DatePicker
                    ref={focusDate}
                    className="datepicker-payscheme"
                    selected={
                      dateInputs?.birthday ? dateInputs?.birthday : null
                    }
                    onChange={(date) => selectUploadDt(date, "birthday")}
                    name="birthday"
                    dateFormat="MM-dd-yyyy"
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    calendarIcon={true}
                    closeCalendar={true}
                    clearIcon={true}
                    maxDate={new Date()}
                    disabled={summaryPage}
                  />
                  <span
                    className="calendar-icon"
                    onClick={handleFocusDate}
                  ></span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.birthday}
                  </Form.Control.Feedback>
                  {validated && (
                    <div className="err-feedback">{errors?.birthday}</div>
                  )}
                </Form.Group>
              </Col>
              <Col
                md={4}
                className={`${
                  (onboardObject?.role === "contractor" &&
                    employeeObject?.contractor_type?.toString() === "1") ||
                  onboardObject?.role === "employee" ||
                  onboardObject?.role === "client-admin" ||
                  onboardObject?.role === "manager"
                    ? "d-block"
                    : " d-none"
                }`}
              >
                <Form.Group className="mb-3" controlId="soc_sec_num">
                  <Form.Label>
                    Social security number <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter social security number"
                    value={employeeObject.soc_sec_num}
                    onChange={handleInput}
                    isInvalid={!!errors.soc_sec_num}
                    name="soc_sec_num"
                    required
                    onBlur={handleFormatChange}
                    onKeyPress={handleKeyPress}
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.soc_sec_num}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                md={4}
                className={`${
                  employeeObject?.contractor_type?.toString() === "0"
                    ? "d-block"
                    : " d-none"
                }`}
              >
                <Form.Group className="mb-3" controlId="business_name">
                  <Form.Label>
                    Business name <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter business name"
                    value={employeeObject.business_name}
                    onChange={handleInput}
                    isInvalid={!!errors.business_name}
                    name="business_name"
                    required
                    maxLength="30"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.business_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                md={4}
                className={`${
                  employeeObject?.contractor_type?.toString() === "0"
                    ? "d-block"
                    : " d-none"
                }`}
              >
                <Form.Group className="mb-3" controlId="federal_ein">
                  <Form.Label>
                    Federal EIN <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter federal EIN"
                    value={employeeObject.federal_ein}
                    onChange={handleInput}
                    isInvalid={!!errors.federal_ein}
                    name="federal_ein"
                    required
                    onBlur={handleFormatChange}
                    onKeyPress={handleKeyPress}
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.federal_ein}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                md={8}
                className={`${
                  onboardObject?.role === "contractor" ||
                  onboardObject?.skip_gusto_payroll === 1
                    ? "d-none"
                    : " d-block"
                }`}
              >
                <Form.Group className="mb-3" controlId="job_location_id">
                  <Form.Label>
                    Work address <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    onChange={handleInput}
                    name="job_location_id"
                    value={employeeObject.job_location_id}
                    required
                    size="lg"
                    disabled={summaryPage}
                  >
                    <option value="">Select address</option>
                    {addressList &&
                      addressList.map((item, index) => (
                        <option key={item.id} value={item.id}>
                          {item.address}
                        </option>
                      ))}
                  </Form.Select>
                  {errors.job_location_id && (
                    <div className="err-feedback">
                      {" "}
                      {errors.job_location_id}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <ContactInfomation
                nextStep={nextStep}
                onboardObject={onboardObject}
                stateList={stateList}
                relationshipList={relationshipList}
                employeePersonalInfoObject={employeeObject}
                personalValidated={setValidated}
                personalErrors={setErrors}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage={summaryPage}
              />
            </Row>
            {/* <div className="text-end mt-4">
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                                className="btn-primary text-white"
                            >
                                Save & Continue
                            </Button>
                        </div> */}
          </Form>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default PersonalInformation;
