import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "./style.scss";
import Signature from "../../../components/Signature";
import Button from "../../../components/Form/Button";
import { postData, postUploadData } from "../../../api";
import { autocloseTiming } from "../../../api/regex";

const OfferLetter = (props) => {
  const { acknowledgedShow, handleGetEmployeeData } = props;
  // eslint-disable-next-line no-unused-vars
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ackShow, setAckShow] = useState(true);
  const [signObject, setSignObject] = useState();
  const [employeeObject, setEmployeeObject] = useState();

  const getOfferLetter = async () => {
    setLoading(true);
    let data = {
      stage: "offer_preview",
    };
    const res = await postData("employee_offer_letter", {}, data);
    if (res.status === true) {
      setLoading(false);
      setEmployeeObject(res?.data?.offer_letter);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append("stage", "offer_sign");
    formData.append("signature_data", signObject);
    const res = await postUploadData("employee_offer_letter", {}, formData);
    if (res.status === true) {
      setLoading(false);
      // acknowledgedShow(true);
      handleGetEmployeeData();
      //   handleClose();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const acknowledgeShow = (data) => {
    setAckShow(data);
  };
  const signatureObj = (data) => {
    if (data?.drawSign !== "" || data?.uploadSign !== "") {
      if (data?.drawSign !== "") {
        setSignObject(data?.drawSign);
      } else {
        setSignObject(data?.uploadSign[0]);
      }
    } else {
      setAckShow(true);
    }
  };
  useEffect(() => {
    getOfferLetter();
  }, []);
  return (
    <>
      <>
        <div className="preview-tool">
          <iframe
            src={
              employeeObject?.document_path !== undefined &&
              employeeObject?.document_path
            }
            className="pdf-doc mb-4"
            name="pdf-view"
            height="263px"
            width="100%"
            title="Iframe Example"
          ></iframe>
        </div>
        <div className="onboard-body mb-3">
          I have read and agreed to the terms in the above document (Please draw
          or upload your signature to agree)
        </div>
        <Form
          noValidate
          validated={validated}
          autoComplete="off"
          className="custom-form"
        >
          <Signature
            className="mb-3"
            acknowledgeShow={acknowledgeShow}
            signatureObj={signatureObj}
          />
          <div className="text-end">
            {/* <Button
              type="button"
              className="btn-default text-blacksix me-3 cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button> */}
            <Button
              type="submit"
              disabled={ackShow}
              loading={loading}
              onClick={handleSubmit}
              className="btn-primary text-white"
            >
              Agree
            </Button>
          </div>
        </Form>
      </>
    </>
  );
};

export default OfferLetter;
