import React from "react";
// import ViewHandbookModal from "./ViewHandbookModal";
import EmployeeSignDocuments from "./EmployeeSignDocuments";
import Button from "../../../components/Form/Button";

function DocumentVerification(props) {
  const { nextStep, signatorySubmit, loading, summaryPage } = props;

  return (
    <>
      <div>
        <div className="document-verification-title-wrapper">
          <h3>Document Authorization</h3>
          <div className="document-verification-subtitle-wrapper">
            <h5>Sign Company documents</h5>
            <p>
              Sign the documents to activate payments and ensure timely employee
              payouts and tax compliance.
            </p>
          </div>
        </div>
        <div className="document-verification-table-wrapper">
          <EmployeeSignDocuments summaryPage={summaryPage} />
        </div>{" "}
        {summaryPage ? null : (
          <div className="text-end mt-4">
            <Button
              type="button"
              className="btn-outline-primary me-2"
              onClick={() => nextStep("state_tax")}
            >
              Back
            </Button>
            <Button
              type="submit"
              disabled={loading}
              loading={loading}
              className="btn-primary text-white"
              onClick={signatorySubmit}
            >
              Save & Continue
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default DocumentVerification;
