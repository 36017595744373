import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./style.scss";
import OnboardHeading from "../../../components/OnboardHeading";
import HeaderForm from "../../../components/HeaderForm";
import FederalTaxSetup from "./FederalTaxSetup";
import AddSignatory from "./AddSignatory";
import { postData, getData, getRevertUser } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import revertIcon from "../../../assets/images/fa_refresh.svg";
import { autocloseTiming } from "../../../api/regex";
import ProgressBar from "./progressBar";
import CompanyDetails from "./companyDetails";
import BankDetailsPayroll from "./bankDetailsPayroll";
import BankVerification from "./bankVerification";
import Steper from "../../../components/StepperProgress";
import ClientGustoSummary from "./ClientGustoSummary";
import { useParams, useLocation } from "react-router-dom";
const ClientOnboardingForms = () => {
  const { step: queryStep } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(queryStep || "add_address");
  const [stepNo, setStepNo] = useState();
  const [title, setTitle] = useState();
  const [clientObject, setClientObject] = useState({});
  const [, setAddressTabActive] = useState(null);
  const [, setFederalTabActive] = useState(null);
  const [, setIndustryTabActive] = useState(null);
  const [, setBankInfoTabActive] = useState(null);
  const [, setPayrollTabActive] = useState(null);
  const [, setSignatoryTabActive] = useState(null);
  const [stepArrays] = useState([
    "add_address",
    "bank_info",
    "federal_tax",
    "bank_verification",
    "add_signatory",
  ]);
  const [newTabArray, setNewTabArray] = useState([]);
  const [stateList, setStateList] = useState();
  const [taxPayerType, setTaxPayerType] = useState();
  const [taxFillingForm, setTaxFillingForm] = useState();
  const [industryType, setIndustryType] = useState();
  const [payFrequencyList, setPayFrequencyList] = useState();
  const [firstPayList, setFirstPayList] = useState();
  const [secondPayList, setSecondPayList] = useState();
  const [titleList, setTitleList] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    getStaticList();
    getClientStep();
  }, []);

  useEffect(() => {
    getTabTitle(step);
    activeTabChange(step);
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    tabActive();
  }, [newTabArray]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updateStepFromQuery = () => {
      const queryStep = location.search.split("step=")[1];
      if (queryStep) {
        setStep(queryStep);
      }
    };
    updateStepFromQuery();
  }, [location.search]);

  // const nextStep = (whichStep) => {
  //   setStep(whichStep);
  //   const url = `${location.pathname}?step=${whichStep}`;
  //   window.history.pushState({}, '', url);
  // };

  const activeTabChange = async (step) => {
    let stepIndex = null;
    for (let index = 0; index < stepArrays.length; index++) {
      const element = stepArrays[index];
      if (step === element) {
        stepIndex = index + 1;
      }
    }
    let newElement = stepArrays.slice(0, stepIndex);
    setNewTabArray([...newTabArray, ...newElement]);
  };

  const tabActive = () => {
    if (newTabArray.includes("add_address")) {
      setAddressTabActive("active");
    }
    if (newTabArray.includes("federal_tax")) {
      setFederalTabActive("active");
    }
    if (newTabArray.includes("industry")) {
      setIndustryTabActive("active");
    }
    if (newTabArray.includes("bank_info")) {
      setBankInfoTabActive("active");
    }
    if (newTabArray.includes("payroll")) {
      setPayrollTabActive("active");
    }
    if (newTabArray.includes("add_signatory")) {
      setSignatoryTabActive("active");
    }
  };
  const getClientStep = async () => {
    setLoading(true);
    const res = await getData("get-gusto-onboarding", {});
    if (res.status === true) {
      const queryStep = new URLSearchParams(window.location.search).get("step");
      const step = queryStep || res.data.status;
      setStep(step);
      // setStep(res.data.status);
      setLoading(false);
      setClientObject(res.data);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const getStaticList = async () => {
    setLoading(true);
    const res = await postData("get_constant", {});
    if (res.status === true) {
      setStateList(res?.data?.stateList);
      setTaxPayerType(res.data.taxPayerType);
      setTaxFillingForm(res.data.taxFillingForm);
      setIndustryType(res?.data?.industryType);
      setPayFrequencyList(res?.data?.payFrequencyList);
      setFirstPayList(res?.data?.firstPayList);
      setSecondPayList(res?.data?.secondPayList);
      setTitleList(res?.data?.titleList);
      setLoading(false);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const getTabTitle = (step) => {
    if (step === "add_address") {
      setTitle("Add addresses");
      setStepNo(1);
    } else if (step === "federal_tax") {
      setTitle("Federal tax info");
      setStepNo(2);
    } else if (step === "industry") {
      setTitle("Select industry");
      setStepNo(3);
    } else if (step === "bank_info") {
      setTitle("Add bank info");
      setStepNo(4);
    } else if (step === "payroll") {
      setTitle("Payroll details");
      setStepNo(5);
    } else if (step === "add_signatory") {
      setTitle("Add signatory");
      setStepNo(6);
    }
  };
  const nextStep = (whichStep) => {
    setStep(whichStep);
    const url = `${location.pathname}?step=${whichStep}`;
    window.history.pushState({}, "", url);
  };

  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  };
  const updateProgressBar = (currentStep) => {
    const currentIndex = stepArrays.indexOf(currentStep);
    if (currentIndex !== -1) {
      const completed = stepArrays.slice(0, currentIndex);
      setCompletedSteps(completed); // Update completed steps
    }
  };
  useEffect(() => {
    updateProgressBar(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      <HeaderForm />{" "}
      {step === "finish_onboarding" ? (
        <ClientGustoSummary
          step={step}
          nextStep={nextStep}
          clientObject={clientObject}
          taxFillingForm={taxFillingForm}
          taxPayerType={taxPayerType}
          payFrequencyList={payFrequencyList}
          firstPayList={firstPayList}
          secondPayList={secondPayList}
          titleList={titleList}
        />
      ) : (
        <div className="boarding-layout board-main-section">
          <Row>
            <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
              <OnboardHeading title="client" />
            </Col>
            {getRevertUser() ? (
              <Col
                md={2}
                sm={getRevertUser() ? 4 : 2}
                className="d-flex align-items-center justify-content-end mb-4 mb-md-0"
              >
                <div className="revert">
                  <Button
                    variant="danger"
                    className="revert_btn"
                    onClick={handleRevertUser}
                  >
                    <img
                      src={revertIcon}
                      alt="revert"
                      className="revert-img me-1"
                    />
                    Revert User
                  </Button>
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
          {!loading && (
            <div className="create-board">
              <div className="board-container">
                <div className="onboard-gusto-tabs Mobile-view justify-content-start">
                  <Steper
                    className="Form-step"
                    max={6}
                    ProgressValue={stepNo}
                  />
                  <h4 className="ms-2 mb-0 align-self-center fw-700">
                    {title}
                  </h4>
                </div>
                <div className="onboard-progress">
                  <ProgressBar
                    step={step}
                    stepArrays={stepArrays}
                    completedSteps={completedSteps}
                    setStepNo={setStepNo}
                  />
                  {/* <h4 className="ms-2 mb-0 align-self-center fw-700">{title}</h4> */}
                </div>
                <div className="onboard-content mt-3 padding-cus-wrapper">
                  {step === "add_address" && (
                    <CompanyDetails
                      nextStep={nextStep}
                      onboardObject={clientObject}
                      stateList={stateList}
                      industryType={industryType}
                      getClientStep={getClientStep}
                    />
                  )}
                  {step === "bank_info" && (
                    <BankDetailsPayroll
                      nextStep={nextStep}
                      onboardObject={clientObject}
                      payFrequencyList={payFrequencyList}
                      firstPayList={firstPayList}
                      secondPayList={secondPayList}
                      getClientStep={getClientStep}
                    />
                  )}
                  {step === "federal_tax" && (
                    <FederalTaxSetup
                      nextStep={nextStep}
                      onboardObject={clientObject}
                      taxPayerType={taxPayerType}
                      taxFillingForm={taxFillingForm}
                      getClientStep={getClientStep}
                    />
                  )}
                  {step === "bank_verification" && (
                    <BankVerification
                      nextStep={nextStep}
                      onboardObject={clientObject}
                      getClientStep={getClientStep}
                    />
                  )}
                  {step === "add_signatory" && (
                    <AddSignatory
                      nextStep={nextStep}
                      onboardObject={clientObject}
                      stateList={stateList}
                      titleList={titleList}
                      getClientStep={getClientStep}
                      setStep={setStep}
                      step={step}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {loading && <Loader />}
        </div>
      )}
    </>
  );
};

export default ClientOnboardingForms;
