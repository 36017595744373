import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  emailRegx,
  mustNumbers,
  mustNumberswithPhone,
  emptySpace,
  ssnRegex,
  onlyCharacterWithSpace,
  onlyCharacterWithSpaceAndComma,
  autocloseTiming,
  characterWithCommaDotHyphenAndApos,
} from "../../../api/regex";
import {
  postData,
  socialSecurityNumberFormat,
  phoneNumberFormat,
} from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import ClientSuccessModal from "./ClientSuccessModal";

const AddSignatory = ({ nextStep, onboardObject, stateList, titleList }) => {
  const focusDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [radioValidated, setRadioValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientObject, setClientObject] = useState({
    primary_email: "",
    first_name: "",
    last_name: "",
    username: "",
    title: "",
    phone_number: "",
    birthday: "",
    str_soc_sec_num: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    preferred_name: "",
    is_signatory: null,
  });
  const [errors, setErrors] = useState({});
  const [stateValue, setStateValue] = useState();
  const [dateInputs, setDateInputs] = useState({
    birthday: null,
  });
  const [show, setShow] = useState(false);
  const [autoUrl, setAutoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const currentDate = new Date();

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (onboardObject?.signatory) {
      setClientObject(onboardObject?.signatory);
      let data = {
        label: onboardObject?.signatory?.state,
        value: onboardObject?.signatory?.state,
      };
      setStateValue(data);
    }
  }, [onboardObject]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const handlePhoneFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = phoneNumberFormat(e.target.value);
    setClientObject((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handlePhoneNumberKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ""); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 10;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item.name,
          label: item.name,
          name: item.name,
        });
      });
    }
    return finalArr;
  };

  const findFormErrors = () => {
    const {
      primary_email,
      first_name,
      username,
      title,
      last_name,
      phone_number,
      str_soc_sec_num,
      birthday,
      address,
      address2,
      city,
      zip,
      preferred_name,
      acknowledge,
    } = clientObject;
    const newErrors = {};
    let newSocialNumber = str_soc_sec_num?.replaceAll("-", "");
    if (!primary_email || primary_email === "")
      newErrors.primary_email = "Please enter a valid email.";
    else if (!primary_email?.match(emailRegx)) {
      newErrors.primary_email = "Please enter a valid email address.";
    }
    if (!first_name || first_name === "")
      newErrors.first_name = "Please enter your first name.";
    else if (first_name.length < 3) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (first_name.length > 70) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (!first_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.first_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    }
    if (preferred_name !== "" && preferred_name !== null) {
      if (preferred_name.length < 3) {
        newErrors.preferred_name =
          "The preferred name should be  3 to 70 characters long.";
      } else if (preferred_name.length > 70) {
        newErrors.preferred_name =
          "The preferred name should be  3 to 70 characters long.";
      } else if (!preferred_name?.match(characterWithCommaDotHyphenAndApos)) {
        newErrors.preferred_name =
          "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
      }
    }
    if (!last_name || last_name === "")
      newErrors.last_name = "Please enter your last name.";
    // else if (last_name.length > 1) {
    //   newErrors.last_name = "Please enter your last name.";
    // }
    // else if (last_name.length > 70) {
    //   newErrors.last_name = "The last name should be  3 to 70 characters long.";
    // }
    else if (!last_name?.match(emptySpace)) {
      newErrors.last_name = "Please enter your last name.";
    } else if (!last_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.last_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    }

    if (!username || username === "")
      newErrors.username = "Please enter your user name.";
    else if (username.length < 3) {
      newErrors.username = "The user name should be  3 to 255 characters long.";
    } else if (username.length > 255) {
      newErrors.username = "The user name should be  3 to 255 characters long.";
    }

    if (!title || title === "") newErrors.title = "Please enter your title.";
    if (phone_number !== "" && phone_number !== null) {
      const Phone_numbers_Only = phone_number?.replace(/\D/g, "");
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.phone_number = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.phone_number = "Please enter a 10 digit phone number";
      }
    }
    if (newSocialNumber !== "" && newSocialNumber !== null) {
      // if (!newSocialNumber || newSocialNumber === "") {
      //   newErrors.str_soc_sec_num = "Please enter your social security number.";
      // }
      if (!newSocialNumber.toString()?.match(mustNumbers)) {
        newErrors.str_soc_sec_num =
          "The social security number should be numeric.";
      } else if (newSocialNumber.length !== 9) {
        newErrors.str_soc_sec_num =
          "The social security number should be 9 characters.";
      } else if (!str_soc_sec_num.toString()?.match(ssnRegex)) {
        newErrors.str_soc_sec_num =
          "Please enter a valid  social security number.";
      }
    }
    //

    // if (!birthday || birthday === "" || birthday === null) newErrors.birthday = "Please enter your date of birth.";
    if (birthday !== "" && birthday !== null) {
      if (
        currentDate.getFullYear() - new Date(birthday).getFullYear() < 13 ||
        (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
          currentDate.getMonth() < new Date(birthday).getMonth()) ||
        (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
          currentDate.getMonth() === new Date(birthday).getMonth() &&
          currentDate.getDate() < new Date(birthday).getDate())
      ) {
        newErrors.birthday = "The age must be at least 13 years old.";
      }
    }
    if (address !== "" && address !== null) {
      // if (!address || address === "") newErrors.address = "Please enter street 1.";
      if (address.length < 5) {
        newErrors.address =
          "The street name should be  5 to 250 characters long.";
      } else if (address.length > 250) {
        newErrors.address =
          "The street name should be  5 to 250 characters long.";
      }
    }
    // if (clientObject.is_signatory === "Yes") {
    //   if (address2 !== "") {
    //     if (address2.length < 5) {
    //       newErrors.address2 =
    //         "The street name should be  5 to 250 characters long.";
    //     } else if (address2.length > 250) {
    //       newErrors.address2 =
    //         "The street name should be  5 to 250 characters long.";
    //     } else if (!address2?.match(emptySpace)) {
    //       newErrors.address2 =
    //         "The street name should be  5 to 250 characters long.";
    //     }
    //   }
    // }
    if (city !== "" && city !== null) {
      // if (!city || city === "") newErrors.city = "Please enter your city.";
      if (!city?.match(onlyCharacterWithSpaceAndComma)) {
        newErrors.city = "The city name should be alphabetic.";
      } else if (city.length < 4) {
        newErrors.city = "The city name should be  4 to 20 characters long.";
      } else if (city.length > 20) {
        newErrors.city = "The city name should be  4 to 20 characters long.";
      }
    }
    // if (!state || state === "") newErrors.state = "Please enter your state.";
    if (zip !== "" && zip !== null) {
      if (!zip || zip === "") newErrors.zip = "Please enter your zip.";
      else if (!zip.toString()?.match(mustNumbers)) {
        newErrors.zip = "The zip should be numeric.";
      } else if (zip.length !== 5) {
        newErrors.zip = "The zip code should be 5 digit.";
      }
    }
    if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";

    return newErrors;
  };

  const findRadioFormErrors = () => {
    const { is_signatory, acknowledge } = clientObject;
    const newErrors = {};
    if (
      !is_signatory ||
      is_signatory === "" ||
      is_signatory === null ||
      is_signatory === undefined
    )
      newErrors.is_signatory = "Please select the signatory.";
    if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      federalTaxSetup();
    }
  };
  const federalTaxSetup = async () => {
    setLoading(true);
    let data = {
      stage: "add_signatory",
      is_signatory: 0,
      ...clientObject,
    };
    data.is_signatory = clientObject.is_signatory === "Yes" ? 1 : 2;
    const res = await postData("client_onboarding_steps", {}, data);
    if (res.status === true) {
      setLoading(false);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      if (res.steps === "completed") {
        setShow(true);
        setAutoUrl(res.auto_url);
        setTitle(res?.title);
        setSubTitle(res?.subtitle);
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleStateOnChange = (data) => {
    setStateValue(data);
    setClientObject((prev) => ({
      ...prev,
      state: data.value,
    }));
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      let val = formatDates(date);
      setClientObject((prev) => ({
        ...prev,
        [name]: val,
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          [name]: null,
        });
    }
  };

  const handleFocusDate = () => {
    const datepickerElement = focusDate.current;
    datepickerElement.setFocus(true);
  };

  const handleFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = socialSecurityNumberFormat(e.target.value);
    setClientObject((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ""); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 9;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };

  const handleRadioChange = (event) => {
    setClientObject((prev) => ({
      ...prev,
      is_signatory: event.target.value,
    }));
  };

  const yesSignatory = async () => {
    setLoading(true);
    let data = {
      stage: "add_signatory",
      is_signatory: clientObject.is_signatory === "Yes" ? 1 : 2,
    };
    const res = await postData("client_onboarding_steps", {}, data);
    if (res.status === true) {
      setLoading(false);
      setAutoUrl(res.auto_url);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      if (res.status === true) {
        setShow(true);
        setAutoUrl(res.auto_url);
        setTitle(res?.title);
        setSubTitle(res?.subtitle);
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
      setShow(false);
    }
  };

  function formRadioSubmit(event) {
    event.preventDefault();
    const newErrors = findRadioFormErrors();
    setRadioValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      yesSignatory();
    }
  }

  const handleCheckAcknowledgeChange = (e) => {
    const name = e.target.name;
    if (e.target.checked === true) {
      setClientObject((prev) => ({
        ...prev,
        acknowledge: true,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          acknowledge: null,
        });
    } else {
      setClientObject((prev) => ({
        ...prev,
        acknowledge: false,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          acknowledge: null,
        });
    }
  };

  return (
    <>
      {!loading && (
        <>
          <div className="assign_signatory_container">
            <h5>
              Assign company signatory <span className="asterisk">*</span>{" "}
            </h5>
            <Form
              noValidate
              validated={radioValidated}
              onSubmit={formRadioSubmit}
              autoComplete="off"
            >
              <Row>
                <Col>
                  <Form.Group
                    className=" is-signatory mb-3"
                    controlId="is_signatory"
                  >
                    <Form.Check
                      type="radio"
                      id={`authorized-radio`}
                      name="Yes"
                      value="Yes"
                      label="Yes, I’m authorized to sign official forms from the company."
                      checked={clientObject.is_signatory === "Yes"}
                      onChange={(e) => handleRadioChange(e)}
                      required
                    />
                    <Form.Check
                      type="radio"
                      id={`appoint-radio`}
                      name="No"
                      value="No"
                      label="No, I’ll appoint another person to sign official forms"
                      checked={clientObject.is_signatory === "No"}
                      onChange={(e) => handleRadioChange(e)}
                      required
                    />
                    {radioValidated &&
                      (!clientObject.is_signatory ||
                        clientObject.is_signatory === "" ||
                        clientObject.is_signatory === null ||
                        clientObject.is_signatory === undefined) && (
                        <div className="err-feedback">
                          {errors.is_signatory}
                        </div>
                      )}
                  </Form.Group>
                </Col>
              </Row>
              {clientObject.is_signatory !== "No" && (
                <>
                  <Row className="check-custom mt-5">
                    <Col md={8} className="align-seld-center">
                      <Form.Group
                        className="bank-check ack-step-last mb-3"
                        controlId="acknowledge"
                      >
                        <Form.Check
                          label={
                            <span>
                              I agree to the
                              <a
                                className="ms-1"
                                href="https://flows.gusto.com/terms"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms of Service
                              </a>
                            </span>
                          }
                          name="acknowledge"
                          type="checkbox"
                          checked={clientObject.acknowledge}
                          value="1"
                          onChange={(e) => handleCheckAcknowledgeChange(e)}
                          id="acknowledge_check"
                          required
                        />
                        {radioValidated && (
                          <div className="err-feedback">
                            {errors.acknowledge}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <div className="text-end">
                        <Button
                          type="button"
                          className="btn-outline-primary me-2"
                          onClick={() => nextStep("payroll")}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          disabled={loading}
                          loading={loading}
                          className="btn-primary text-white"
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </div>
          {clientObject.is_signatory === "No" && (
            <div className="board-right board-tab-sections mt-3">
              <h5 className="mb-3"> Add signatory</h5>

              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                autoComplete="off"
                className="custom-form mb-4"
              >
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="first_name">
                      <Form.Label>
                        First name <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        value={clientObject.first_name}
                        onChange={handleInput}
                        isInvalid={!!errors.first_name}
                        name="first_name"
                        maxLength="70"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.first_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="preferred_name">
                      <Form.Label>Preferred Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Preferred Name"
                        value={clientObject.preferred_name}
                        onChange={handleInput}
                        isInvalid={!!errors.preferred_name}
                        name="preferred_name"
                        maxLength="70"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.preferred_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>
                        Last name <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        value={clientObject.last_name}
                        onChange={handleInput}
                        isInvalid={!!errors.last_name}
                        name="last_name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.last_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="username">
                      <Form.Label>
                        User name <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Create user name"
                        value={clientObject.username}
                        onChange={handleInput}
                        isInvalid={!!errors.username}
                        name="username"
                        maxLength="255"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="primary_email">
                      <Form.Label>
                        Email <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={clientObject.primary_email}
                        onChange={handleInput}
                        isInvalid={!!errors.primary_email}
                        name="primary_email"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.primary_email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label>
                        Title <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Select
                        onChange={handleInput}
                        name="title"
                        value={clientObject.title}
                        required
                        size="lg"
                      >
                        <option value="">Select title</option>
                        {titleList &&
                          titleList.map((item, index) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="phone_number">
                      <Form.Label>Phone number </Form.Label>
                      <InputGroup className="phone-group">
                        <InputGroup.Text>+1</InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="Enter phone number"
                          value={clientObject.phone_number}
                          onChange={handleInput}
                          isInvalid={!!errors.phone_number}
                          name="phone_number"
                          onBlur={handlePhoneFormatChange}
                          onKeyPress={handlePhoneNumberKeyPress}
                          // maxLength="10"
                          // required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone_number}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group
                      className="position-relative mb-3 personal-date-picker"
                      controlId="birthday"
                    >
                      <Form.Label>Date of birth </Form.Label>
                      <DatePicker
                        ref={focusDate}
                        className="datepicker-payscheme"
                        selected={
                          dateInputs?.birthday ? dateInputs?.birthday : null
                        }
                        onChange={(date) => selectUploadDt(date, "birthday")}
                        name="birthday"
                        dateFormat="MM-dd-yyyy"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        calendarIcon={true}
                        closeCalendar={true}
                        clearIcon={true}
                        maxDate={new Date()}
                      />
                      <span
                        className="calendar-icon"
                        onClick={handleFocusDate}
                      ></span>
                      {validated && (
                        <div className="err-feedback">{errors?.birthday}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="str_soc_sec_num">
                      <Form.Label>Social security number </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter social security number"
                        value={clientObject.str_soc_sec_num}
                        onChange={handleInput}
                        isInvalid={!!errors.str_soc_sec_num}
                        name="str_soc_sec_num"
                        // required
                        onBlur={handleFormatChange}
                        onKeyPress={handleKeyPress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.str_soc_sec_num}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <h5 className="mb-3">Signatory home address</h5>

                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="address">
                      <Form.Label>Street 1 </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter street 1"
                        value={clientObject.address}
                        onChange={handleInput}
                        isInvalid={!!errors.address}
                        name="address"
                        maxLength="250"
                        // required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="address2">
                      <Form.Label>Street 2 (Optional)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter street 2 "
                        value={clientObject.address2}
                        onChange={handleInput}
                        // isInvalid={!!errors.address2}
                        name="address2"
                        maxLength="250"
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errors.address2}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="city">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter city"
                        value={clientObject.city}
                        onChange={handleInput}
                        isInvalid={!!errors.city}
                        name="city"
                        maxLength="20"
                        // required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="state">
                      <Form.Label>State </Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={formatSelectOptions(stateList)}
                        onChange={(selectedOption) =>
                          handleStateOnChange(selectedOption)
                        }
                        value={stateValue}
                        placeholder="Select state"
                        className="custom-select"
                      />
                      {/* {validated && clientObject?.state === "" && (
                        <div className="err-feedback">
                          Please enter state.
                        </div>
                      )} */}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="zip">
                      <Form.Label>Zip Code </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter zip code"
                        value={clientObject.zip}
                        onChange={handleInput}
                        isInvalid={!!errors.zip}
                        name="zip"
                        // required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zip}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <>
                  <Row className="check-custom mt-5 ">
                    <Col md={8} className="align-self-center">
                      <Form.Group
                        className="bank-check ack-step-last mb-3"
                        controlId="acknowledge"
                      >
                        <Form.Check
                          label={
                            <span>
                              I agree to the
                              <a
                                className="ms-1"
                                href="https://flows.gusto.com/terms"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms of Service
                              </a>
                            </span>
                          }
                          name="acknowledge"
                          type="checkbox"
                          checked={clientObject.acknowledge}
                          value="1"
                          id="check_acknowledge"
                          onChange={(e) => handleCheckAcknowledgeChange(e)}
                          required
                        />
                        {validated && (
                          <div className="err-feedback">
                            {errors.acknowledge}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <div className="text-end">
                        <Button
                          type="button"
                          className="btn-outline-primary me-2"
                          onClick={() => nextStep("payroll")}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          disabled={loading}
                          loading={loading}
                          className="btn-primary text-white"
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              </Form>
            </div>
          )}
          {
            <ClientSuccessModal
              show={show}
              onClose={handleClose}
              autoUrl={autoUrl}
              title={title}
              subtitle={subtitle}
            />
          }
        </>
      )}
      {loading && <Loader />}
    </>
  );
};

export default AddSignatory;
