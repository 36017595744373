import React, { useState } from "react";
import FormModal from "../../../components/FormModal";
import Button from "../../../components/Form/Button";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, cakePhpURL } from "../../../api";
import checkCircle from "../../../assets/images/check_circle.svg";
import Loader from "../../../components/Loader";

const ClientSuccessModal = ({
  show,
  handleClose,
  title,
  subtitle,
  redirectionUrl,
}) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleNavigate = () => {
    if (
      redirectionUrl.startsWith("http://") ||
      redirectionUrl.startsWith("https://")
    ) {
      window.open(redirectionUrl, "_self");
    } else {
      navigate(redirectionUrl, { replace: true });
    }
  };

  return (
    <>
      {!loading && (
        <FormModal
          show={show}
          onClose={handleClose}
          size="md"
          backdrop="static"
          modalClassName="successModal"
        >
          <div>
            <div>
              <img src={checkCircle} alt="icon" />
            </div>
            <div className="sub-title py-2">{subtitle}</div>
            <div className="my-2 text-center">
              <Button
                type="button"
                className="btn-primary text-white"
                onClick={handleNavigate}
              >
                Continue
              </Button>
            </div>
          </div>
        </FormModal>
      )}
      {loading && <Loader />}
    </>
  );
};

export default ClientSuccessModal;
