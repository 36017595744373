import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";

function NoSignatory(props) {
  const {
    validated,
    noErrors,
    handleNoInput,
    clientNoObject,
    titleList,
    summaryPage,
    formatSelectOptions,
    getSalaryData,
    handleNoEmploymentTypeChange,
    handleNoInputChange,
    handleNoSalaryTypeChange,
    handleNoRadioChange,
    handleNoFlsaChange,
  } = props;
  const selectedOption = formatSelectOptions(getSalaryData.flsa).find(
    (option) => option.value === clientNoObject.flsa
  );
  const getCompensationUnit = (compensationName) => {
    switch (compensationName) {
      case "ANNUAL SALARY":
        return "year";
      case "HOURLY RATE":
        return "hour";
      case "WEEKLY RATE":
        return "week";
      default:
        return "";
    }
  };

  return (
    <Form
      noValidate
      validated={validated}
      autoComplete="off"
      className="custom-form mb-4"
    >
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="first_name">
            <Form.Label>
              Name <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="first_name"
              maxLength="70"
              required
              value={clientNoObject.first_name}
              onChange={handleNoInput}
              disabled={summaryPage}
            />
            {noErrors.name && (
              <div className="err-feedback">{noErrors.name}</div>
            )}
          </Form.Group>
        </Col>{" "}
        <Col md={4}>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>
              User name <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter user name"
              name="username"
              maxLength="255"
              required
              value={clientNoObject.username}
              onChange={handleNoInput}
              disabled={summaryPage}
            />
            <Form.Control.Feedback type="invalid">
              {noErrors.username}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="primary_email">
            <Form.Label>
              Email <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              isInvalid={!!noErrors.primary_email}
              name="primary_email"
              value={clientNoObject.primary_email}
              required
              onChange={handleNoInput}
              disabled={summaryPage}
            />
            <Form.Control.Feedback type="invalid">
              {noErrors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>
              Title <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Select
              onChange={handleNoInput}
              name="title"
              value={clientNoObject.title}
              required
              size="lg"
              disabled={summaryPage}
            >
              <option value="">Select title</option>
              {titleList &&
                titleList.map((item, index) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {noErrors.title}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Label>
          Would you like to be included in the company’s payroll system?{" "}
          <span className="asterisk">*</span>
        </Form.Label>
        <Form.Group className=" is-signatory mb-3" controlId="is_signatory">
          <Form.Check
            type="radio"
            id={`authorized-radio-gusto`}
            name="Yes"
            value="1"
            label="Yes"
            checked={
              clientNoObject.is_Payroll === "1" ||
              clientNoObject.is_Payroll === 1
            }
            onChange={(e) => handleNoRadioChange(e)}
            required
            disabled={summaryPage}
          />
          <Form.Check
            type="radio"
            id={`appoint-radio-gusto`}
            name="No"
            value="2"
            label="No"
            checked={
              clientNoObject.is_Payroll === "2" ||
              clientNoObject.is_Payroll === 2
            }
            onChange={(e) => handleNoRadioChange(e)}
            required
            disabled={summaryPage}
          />
        </Form.Group>
        {noErrors.is_Payroll && (
          <div className="err-feedback">{noErrors.is_Payroll}</div>
        )}
      </Row>
      {clientNoObject.is_Payroll === 1 || clientNoObject.is_Payroll === "1" ? (
        <>
          <Row>
            <div className="onboarding-salary-type-wrapper">
              <Form.Label>
                Status <span className="asterisk">*</span>
              </Form.Label>
              <div className="onboarding-employment-sub-wrapper">
                {getSalaryData &&
                  getSalaryData?.status &&
                  Object.entries(getSalaryData?.status)?.map(
                    ([type, label], index) => {
                      const radioId = `employment-type-radio-${index}`;
                      return (
                        <Form.Check
                          key={index}
                          type="radio"
                          id={radioId}
                          name="employmentType"
                          value={type}
                          label={label}
                          checked={clientNoObject.emp_status === type}
                          onChange={(e) => handleNoEmploymentTypeChange(e)}
                          disabled={summaryPage}
                        />
                      );
                    }
                  )}
              </div>{" "}
              {validated &&
                (clientNoObject?.emp_status === "" ||
                  clientNoObject?.emp_status === null) && (
                  <div className="err-feedback">
                    Please select Employement Type
                  </div>
                )}
            </div>
          </Row>
          <Row>
            <div className="onboarding-employment-wrapper">
              <Form.Label>
                Compensation <span className="asterisk">*</span>
              </Form.Label>
              <div className="onboarding-employment-sub-wrapper">
                {getSalaryData?.compensation?.map((data, index) => {
                  const radioId = `salary-type-${index}`;
                  return (
                    <div className="onboarding-salary-sub-wrapper position-relative">
                      <Form.Check
                        type="radio"
                        id={radioId}
                        name="salaryType"
                        value={data.name}
                        label={
                          data.name.charAt(0).toUpperCase() +
                          data.name.slice(1).toLowerCase()
                        }
                        onChange={() => handleNoSalaryTypeChange(data.name)}
                        checked={clientNoObject.compensation_name === data.name}
                        disabled={summaryPage}
                      />
                    </div>
                  );
                })}
              </div>{" "}
              {validated && clientNoObject?.compensation_name === "" && (
                <div className="err-feedback">
                  Please select compensation type
                </div>
              )}
              <div className="salary-input-wrapper">
                <Form.Control
                  type="text"
                  placeholder="$ ___,___.__"
                  disabled={
                    clientNoObject.compensation_name === "" ||
                    clientNoObject?.compensation_name === null ||
                    summaryPage
                  }
                  value={clientNoObject?.salary}
                  onChange={(e) => handleNoInputChange(e.target.value)}
                  onKeyPress={(e) => {
                    if (!/^\d*\.?\d{0,2}$/.test(e.target.value + e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="salary-unit">
                  /{getCompensationUnit(clientNoObject.compensation_name)}
                </span>
              </div>
              {validated &&
                (clientNoObject?.salary === "" ||
                  clientNoObject?.salary === null) && (
                  <div className="err-feedback">{noErrors.salary}</div>
                )}
            </div>
          </Row>
          <Row>
            <div className="onboarding-dropdown-wrapper">
              {/* <div className="dropdown-wrapper-cus">
                      <Form.Label>
                        Choose pay frequency <span className="asterisk">*</span>
                      </Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={formatSelectOptions(getSalaryData.frequency)}
                        placeholder="Select one"
                        className="custom-select"
                      />
                    </div> */}
              <div className="dropdown-wrapper-cus">
                <Form.Label>
                  FLSA classification
                  <span className="asterisk">*</span>
                </Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={formatSelectOptions(getSalaryData.flsa)}
                  placeholder="Select FLSA classification"
                  className="custom-select"
                  onChange={handleNoFlsaChange}
                  isDisabled={summaryPage}
                  value={selectedOption}
                />{" "}
              </div>{" "}
            </div>{" "}
            {validated &&
              (clientNoObject?.flsa === "" ||
                clientNoObject?.flsa === null) && (
                <div className="err-feedback">
                  Please select flsa classification
                </div>
              )}
          </Row>
        </>
      ) : null}
    </Form>
  );
}

export default NoSignatory;
