import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import InputGroup from "react-bootstrap/InputGroup";
import {
  mustNumberswithPhone,
  onlyCharacterWithSpace,
  emptySpace,
  onlyCharacterWithSpaceAndComma,
  autocloseTiming,
  mustNumbers,
  characterWithCommaDotHyphenAndApos,
  ssnRegex,
} from "../../../api/regex";
import { postData, phoneNumberFormat } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const EmergencyContact = (props) => {
  const {
    nextStep,
    onboardObject,
    relationshipList,
    employeePersonalInfoObject,
    personalValidated,
    personalErrors,
    contactInfoObject,
    contactValidated,
    contactErrors,
    handleGetEmployeeData,
    summaryPage,
  } = props;
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeObject, setEmployeeObject] = useState({
    pc_fullname: "",
    pc_relation: "",
    pc_other: "",
    pc_phone: "",
    pc_alternate_phone: "",
    //
    sc_fullname: "",
    sc_relation: "",
    sc_other: "",
    sc_phone: "",
    sc_alternate_phone: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (onboardObject?.emergency_contact) {
      setEmployeeObject(onboardObject?.emergency_contact);
    }
  }, [onboardObject]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEmployeeObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const handlePhoneFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = phoneNumberFormat(e.target.value);
    setEmployeeObject((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handlePhoneNumberKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ""); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 10;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };
  const currentDate = new Date();

  const findFormErrors = () => {
    const {
      pc_fullname,
      pc_relation,
      pc_alternate_phone,
      pc_other,
      pc_phone,
      sc_fullname,
      sc_alternate_phone,
      sc_other,
      sc_phone,
    } = employeeObject;
    const {
      first_name,
      preferred_name,
      middle_name,
      last_name,
      gender,
      birthday,
      soc_sec_num,
      job_location_id,
      contractor_type,
      business_name,
      federal_ein,
    } = employeePersonalInfoObject;
    const { address, city, state, zip, phone_number, acknowledge } =
      contactInfoObject;
    const newErrors = {};
    if (!pc_fullname || pc_fullname === "")
      newErrors.pc_fullname = "Please enter your full name.";
    else if (pc_fullname.length < 3) {
      newErrors.pc_fullname =
        "The full name should be  3 to 70 characters long.";
    } else if (pc_fullname.length > 70) {
      newErrors.pc_fullname =
        "The full name should be  3 to 70 characters long.";
    } else if (!pc_fullname?.match(emptySpace)) {
      newErrors.pc_fullname = "The full name should be alphabetic.";
    } else if (!pc_fullname?.match(onlyCharacterWithSpace)) {
      newErrors.pc_fullname = "The full name should be alphabetic.";
    }
    if (!pc_relation || pc_relation === "")
      newErrors.pc_relation = "Please select relationship.";
    if (employeeObject.pc_relation === "Other") {
      if (!pc_other || pc_other === "")
        newErrors.pc_other = "Please enter your other relationship.";
      else if (pc_other.length < 3) {
        newErrors.pc_other =
          "The other relationship  should be  3 to 20 characters long.";
      } else if (pc_other.length > 20) {
        newErrors.pc_other =
          "The other relationship should be  3 to 20 characters long.";
      } else if (!pc_other?.match(onlyCharacterWithSpaceAndComma)) {
        newErrors.pc_other = "The full name should be alphabetic";
      }
    }

    if (!pc_phone || pc_phone === "" || pc_phone === null)
      newErrors.pc_phone = "Please enter your phone number.";

    if (pc_phone !== "") {
      const Phone_numbers_Only = pc_phone?.replace(/\D/g, "");
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.pc_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.pc_phone = "Please enter a 10 digit phone number";
      }
    }
    if (pc_alternate_phone !== "" && pc_alternate_phone !== undefined) {
      const Phone_numbers_Only = pc_alternate_phone?.replace(/\D/g, "");
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.pc_alternate_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.pc_alternate_phone = "Please enter a 10 digit phone number";
      }
    }

    // secondary

    if (sc_fullname !== "" && sc_fullname !== undefined) {
      if (sc_fullname.length < 3) {
        newErrors.sc_fullname =
          "The full name should be  3 to 70 characters long.";
      } else if (sc_fullname.length > 70) {
        newErrors.sc_fullname =
          "The full name should be  3 to 70 characters long.";
      } else if (!sc_fullname?.match(onlyCharacterWithSpace)) {
        newErrors.sc_fullname = "The full name should be alphabetic.";
      } else if (!sc_fullname?.match(emptySpace)) {
        newErrors.sc_fullname = "The full name should be alphabetic.";
      }
    }

    if (employeeObject.sc_relation === "Other") {
      if (!sc_other || sc_other === "")
        newErrors.sc_other = "Please enter your other relationship.";
      else if (sc_other.length < 3) {
        newErrors.sc_other =
          "The other relationship  should be  3 to 20 characters long.";
      } else if (sc_other.length > 20) {
        newErrors.sc_other =
          "The other relationship should be  3 to 20 characters long.";
      } else if (!sc_other?.match(onlyCharacterWithSpaceAndComma)) {
        newErrors.sc_other = "The full name should be alphabetic.";
      }
    }

    if (sc_phone !== "" && sc_phone !== undefined) {
      const Phone_numbers_Only = sc_phone?.replace(/\D/g, "");
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.sc_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.sc_phone = "Please enter a 10 digit phone number";
      }
    }
    if (sc_alternate_phone !== "" && sc_alternate_phone !== undefined) {
      const Phone_numbers_Only = sc_alternate_phone?.replace(/\D/g, "");
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.sc_alternate_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.sc_alternate_phone = "Please enter a 10 digit phone number";
      }
    }
    let newFedNumber = federal_ein?.replaceAll("-", "");
    if (!first_name || first_name === "")
      newErrors.first_name = "Please enter your first name.";
    else if (first_name.length < 3) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (first_name.length > 70) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (!first_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.first_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    } else if (!first_name?.match(emptySpace)) {
      newErrors.first_name = "The first name should be alphabetic.";
    }
    // if (preferred_name !== "" && preferred_name !== null) {
    //   if (preferred_name.length < 3) {
    //     newErrors.preferred_name =
    //       "The preferred name should be  3 to 70 characters long.";
    //   } else if (preferred_name.length > 70) {
    //     newErrors.preferred_name =
    //       "The preferred name should be  3 to 70 characters long.";
    //   } else if (!preferred_name?.match(characterWithCommaDotHyphenAndApos)) {
    //     newErrors.preferred_name =
    //       "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    //   } else if (!preferred_name?.match(emptySpace)) {
    //     newErrors.preferred_name = "The preferred name should be alphabetic.";
    //   }
    // }
    // if (middle_name !== "" && middle_name !== null) {
    //   if (middle_name.length < 3) {
    //     newErrors.middle_name =
    //       "The middle name should be  3 to 50 characters long.";
    //   } else if (middle_name.length > 50) {
    //     newErrors.middle_name =
    //       "The middle name should be  3 to 50 characters long.";
    //   } else if (!middle_name?.match(characterWithCommaDotHyphenAndApos)) {
    //     newErrors.middle_name =
    //       "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    //   } else if (!middle_name?.match(emptySpace)) {
    //     newErrors.middle_name = "The middle name should be alphabetic";
    //   }
    // }
    if (!last_name || last_name === "")
      newErrors.last_name = "Please enter your last name.";
    // else if (last_name.length < 3) {
    //     newErrors.last_name = "The last name should be  3 to 70 characters long.";
    // } else if (last_name.length > 70) {
    //     newErrors.last_name = "The last name should be  3 to 70 characters long.";
    // }
    else if (!last_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.last_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    } else if (!last_name?.match(emptySpace)) {
      newErrors.last_name = "Please enter your last name.";
    }
    if (!gender || gender === "" || gender === null)
      newErrors.gender = "Please select gender.";
    if (!birthday || birthday === "" || birthday === null)
      newErrors.birthday = "Please enter your date of birth.";
    // if (onboardObject?.skip_gusto_payroll === 1) {
    else if (
      currentDate.getFullYear() - new Date(birthday).getFullYear() < 13 ||
      (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
        currentDate.getMonth() < new Date(birthday).getMonth()) ||
      (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
        currentDate.getMonth() === new Date(birthday).getMonth() &&
        currentDate.getDate() < new Date(birthday).getDate())
    ) {
      newErrors.birthday = "The age must be at least 13 years old.";
    }
    // }
    // if (
    //   (onboardObject?.role === "contractor" &&
    //     employeeObject?.contractor_type?.toString() === "1") ||
    //   onboardObject?.role === "employee" ||
    //   onboardObject?.role === "client-admin" ||
    //   onboardObject?.role === "manager"
    // ) {
    if (!soc_sec_num || soc_sec_num === "" || soc_sec_num === null) {
      newErrors.soc_sec_num = "Please enter your social security number.";
    } else {
      const normalizedSSN = soc_sec_num.replace(/-/g, ""); // Remove dashes
      if (!normalizedSSN.match(mustNumbers)) {
        newErrors.soc_sec_num = "The social security number should be numeric.";
      } else if (normalizedSSN.length !== 9) {
        newErrors.soc_sec_num =
          "The social security number should be 9 characters.";
      } else if (!soc_sec_num.match(ssnRegex)) {
        newErrors.soc_sec_num = "Please enter a valid social security number.";
      }
    }

    // }

    if (onboardObject?.role === "contractor") {
      if (
        !contractor_type ||
        contractor_type === "" ||
        contractor_type === null
      )
        newErrors.contractor_type = "Please select type.";
      if (employeeObject?.contractor_type?.toString() === "0") {
        if (!business_name || business_name === "")
          newErrors.business_name = "Please enter your business name.";
        else if (business_name.length < 5) {
          newErrors.business_name =
            "The business name should be  5 to 30 characters long";
        } else if (business_name.length > 30) {
          newErrors.business_name =
            "The business name should be  5 to 30 characters long";
        } else if (!business_name?.match(onlyCharacterWithSpace)) {
          newErrors.business_name = "The business name should be alphabetic";
        } else if (!business_name?.match(emptySpace)) {
          newErrors.business_name = "The business name should be alphabetic";
        }

        if (!newFedNumber || newFedNumber === "")
          newErrors.federal_ein = "Please enter your federal EIN number.";
        else if (!newFedNumber?.match(mustNumbers)) {
          newErrors.federal_ein = "The federal EIN number should be numbers";
        } else if (newFedNumber.length !== 9) {
          newErrors.federal_ein =
            "The federal EIN number should be 9 characters";
        }
      }
    }
    //
    // if (
    //   (onboardObject?.role === "employee" ||
    //     onboardObject?.role === "client-admin" ||
    //     onboardObject?.role === "manager") &&
    //   onboardObject?.skip_gusto_payroll !== 1
    // ) {
    // if (!job_location_id || job_location_id === "")
    //   newErrors.job_location_id = "Please enter your work address.";
    // }

    if (!address || address === "") newErrors.address = "Please enter address.";
    else if (address.length < 5) {
      newErrors.address =
        "The address name should be  5 to 250 characters long.";
    } else if (address.length > 250) {
      newErrors.address =
        "The address name should be  5 to 250 characters long.";
    }
    if (!city || city === "") newErrors.city = "Please enter your city.";
    else if (!city?.match(onlyCharacterWithSpaceAndComma)) {
      newErrors.city = "The city name should be alphabetic.";
    } else if (city.length < 4) {
      newErrors.city = "The city name should be  4 to 20 characters long.";
    } else if (city.length > 20) {
      newErrors.city = "The city name should be  4 to 20 characters long.";
    } else if (!city?.match(emptySpace)) {
      newErrors.city = "The city name should be alphabetic.";
    }
    if (!state || state === "") newErrors.state = "Please enter your state.";
    if (!zip || zip === "") newErrors.zip = "Please enter your zip.";
    else if (!zip.toString()?.match(mustNumbers)) {
      newErrors.zip = "The zip should be numeric.";
    } else if (zip.length !== 5) {
      newErrors.zip = "The zip should be 5 digits.";
    }
    if (!phone_number || phone_number === "")
      newErrors.phone_number = "Please enter your phone number.";
    else if (phone_number !== "") {
      const Phone_numbers_Only = phone_number?.replace(/\D/g, "");
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.phone_number = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.phone_number = "Please enter a 10 digit phone number";
      }
    }
    if (onboardObject?.skip_gusto_payroll === 1) {
      if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";
    }
    if (onboardObject?.skip_gusto_payroll !== 1) {
      if (!job_location_id || job_location_id === "")
        newErrors.job_location_id = "Please enter your work address.";
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    personalValidated(true);
    contactValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      personalErrors(newErrors);
      contactErrors(newErrors);
    } else {
      emergencyContact();
    }
  };

  const emergencyContact = async () => {
    setLoading(true);
    let data = {
      ...employeeObject,
      ...contactInfoObject,
      ...employeePersonalInfoObject,
    };
    const res = await postData("gusto-employee-personal-info", {}, data);
    if (res.status === true) {
      setLoading(false);
      nextStep(res.next_steps);
      handleGetEmployeeData();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <div className="board-right board-tab-sections">
          <h5 className="mb-3"> Emergency contact</h5>

          <Form
            noValidate
            validated={validated}
            // onSubmit={handleSubmit}
            autoComplete="off"
            className="custom-form mb-4"
          >
            <h4 className="emp-sub-title mb-3">Primary Contact</h4>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="pc_fullname">
                  <Form.Label>
                    Full name <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter full name"
                    value={employeeObject.pc_fullname}
                    onChange={handleInput}
                    isInvalid={!!errors.pc_fullname}
                    name="pc_fullname"
                    maxLength="70"
                    required
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pc_fullname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="pc_relation">
                  <Form.Label>
                    Relationship <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    onChange={handleInput}
                    name="pc_relation"
                    value={employeeObject.pc_relation}
                    required
                    size="lg"
                    disabled={summaryPage}
                  >
                    <option value="">Select relationship</option>
                    {relationshipList &&
                      relationshipList.map((item, index) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.pc_relation}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                md={4}
                className={`${
                  employeeObject.pc_relation === "Other" ? "d-block" : "d-none"
                }`}
              >
                <Form.Group className="mb-3" controlId="pc_other">
                  <Form.Label>
                    Other <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter other"
                    value={employeeObject.pc_other}
                    onChange={handleInput}
                    isInvalid={!!errors.pc_other}
                    name="pc_other"
                    maxLength="70"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pc_other}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="pc_phone">
                  <Form.Label>
                    Phone number <span className="asterisk">*</span>
                  </Form.Label>
                  <InputGroup className="phone-group">
                    <InputGroup.Text>+1</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone number"
                      value={employeeObject.pc_phone}
                      onChange={handleInput}
                      isInvalid={!!errors.pc_phone}
                      name="pc_phone"
                      // maxLength="10"
                      onBlur={handlePhoneFormatChange}
                      onKeyPress={handlePhoneNumberKeyPress}
                      required
                      disabled={summaryPage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pc_phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="pc_alternate_phone">
                  <Form.Label>Alternate Phone number </Form.Label>
                  <InputGroup className="phone-group">
                    <InputGroup.Text>+1</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter alternate phone number"
                      value={employeeObject.pc_alternate_phone}
                      onChange={handleInput}
                      isInvalid={!!errors.pc_alternate_phone}
                      name="pc_alternate_phone"
                      onBlur={handlePhoneFormatChange}
                      onKeyPress={handlePhoneNumberKeyPress}
                      // maxLength="10"
                      disabled={summaryPage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pc_alternate_phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <h4 className="emp-sub-title my-3">Secondary Contact (Optional)</h4>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="sc_fullname">
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter full name"
                    value={employeeObject.sc_fullname}
                    onChange={handleInput}
                    isInvalid={!!errors.sc_fullname}
                    name="sc_fullname"
                    maxLength="70"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.sc_fullname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="sc_relation">
                  <Form.Label>Relationship</Form.Label>
                  <Form.Select
                    onChange={handleInput}
                    name="sc_relation"
                    value={employeeObject.sc_relation}
                    size="lg"
                    disabled={summaryPage}
                  >
                    <option value="">Select relationship</option>
                    {relationshipList &&
                      relationshipList.map((item, index) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.sc_relation}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                md={4}
                className={`${
                  employeeObject.sc_relation === "Other" ? "d-block" : "d-none"
                }`}
              >
                <Form.Group className="mb-3" controlId="sc_other">
                  <Form.Label>Other</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter other relationship"
                    value={employeeObject.sc_other}
                    onChange={handleInput}
                    isInvalid={!!errors.sc_other}
                    name="sc_other"
                    maxLength="70"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.sc_other}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="sc_phone">
                  <Form.Label>Phone number </Form.Label>
                  <InputGroup className="phone-group">
                    <InputGroup.Text>+1</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone number"
                      value={employeeObject.sc_phone}
                      onChange={handleInput}
                      isInvalid={!!errors.sc_phone}
                      name="sc_phone"
                      onBlur={handlePhoneFormatChange}
                      onKeyPress={handlePhoneNumberKeyPress}
                      maxLength="70"
                      disabled={summaryPage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sc_phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="sc_alternate_phone">
                  <Form.Label>Alternate Phone number </Form.Label>
                  <InputGroup className="phone-group">
                    <InputGroup.Text>+1</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter alternate phone number"
                      value={employeeObject.sc_alternate_phone}
                      onChange={handleInput}
                      isInvalid={!!errors.sc_alternate_phone}
                      name="sc_alternate_phone"
                      onBlur={handlePhoneFormatChange}
                      onKeyPress={handlePhoneNumberKeyPress}
                      // maxLength="10"
                      disabled={summaryPage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sc_alternate_phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            {summaryPage ? null : (
              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn-outline-primary me-2"
                  onClick={() => nextStep("offer_letter")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  onClick={handleSubmit}
                  className="btn-primary text-white"
                >
                  Save & Continue
                </Button>
              </div>
            )}
          </Form>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default EmergencyContact;
