import React from "react";
import { Accordion } from "react-bootstrap";
import "./employeeOnboardingSummary.scss";
// import PlusIcon from "../../../assets/images/onboarding_icons/add.svg";
// import RemoveIcon from "../../../assets/images/onboarding_icons/remove.svg";
import EditIcon from "../../../assets/images/onboarding_icons/edit_square.svg";
import PersonalInformation from "./PersonalInformation";
import EmployeeAgreement from "./EmployeeAgreement";
import FederalTaxSetup from "./FederalTaxSetup";
// import OnboardingsTask from "./OnboardingsTask";
import DirectDeposit from "./DirectDeposit";
import StatetaxTab from "../../Profile/Statetax";
import DocumentVerification from "./DocumentVerification";
import EligibilityForm from "./EligibilityForm";

function AccordionComp(props) {
  const {
    nextStep,
    tabsList,
    clientObject,
    relationshipList,
    stateList,
    handleGetEmployeeData,
    reloadCallback,
    empAgreeTabActive,
    federalList,
    signatorySubmit,
    loading,
    eligibilityList,
    countryList,
  } = props;
  const renderHeader = (title, step) => (
    <div className="onboarding-accordion-custom-header d-flex align-items-center justify-content-between">
      <span>{title}</span>
      <div className="header-icons">
        <img
          src={EditIcon}
          alt="Edit Icon"
          className="onboarding-accordion-edit-icon"
          onClick={() => nextStep(step)}
        />
      </div>
    </div>
  );

  return (
    <div>
      <Accordion
        defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7"]}
        alwaysOpen
        className="custom-accordion"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {renderHeader("Personal Information", "personal_info")}
          </Accordion.Header>
          <Accordion.Body>
            {" "}
            <PersonalInformation
              nextStep={nextStep}
              onboardObject={clientObject}
              stateList={stateList}
              relationshipList={relationshipList}
              handleGetEmployeeData={handleGetEmployeeData}
              summaryPage
            />
          </Accordion.Body>
        </Accordion.Item>
        {tabsList.includes("employee_agreement") && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {renderHeader("Employee Agreement", "employee_agreement")}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <EmployeeAgreement
                nextStep={nextStep}
                onboardObject={clientObject}
                stateList={stateList}
                reloadCallback={reloadCallback}
                empAgreeTabActive={empAgreeTabActive}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {tabsList.includes("direct_deposit") && (
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              {renderHeader("Direct Deposit", "direct_deposit")}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <DirectDeposit
                nextStep={nextStep}
                onboardObject={clientObject}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage
              />
            </Accordion.Body>
          </Accordion.Item>
        )}{" "}
        {tabsList.includes("federal_tax") && (
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              {renderHeader("Federal Tax", "federal_tax")}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <FederalTaxSetup
                nextStep={nextStep}
                onboardObject={clientObject}
                federalList={federalList}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {/* {tabsList.includes("onboarding_tasks") && (
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              {renderHeader("Onboarding Tasks", "onboarding_tasks")}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <OnboardingsTask
                nextStep={nextStep}
                onboardObject={clientObject}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage
              />
            </Accordion.Body>
          </Accordion.Item>
        )} */}
        {tabsList.includes("state_tax") && (
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              {renderHeader("State Tax", "state_tax")}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <StatetaxTab
                userId={clientObject?.user_id}
                onboardObject={clientObject}
                onboardingForm
                nextStep={nextStep}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {tabsList.includes("document_verification") && (
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              {renderHeader("Document Verification", "document_verification")}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              <DocumentVerification
                nextStep={nextStep}
                signatorySubmit={signatorySubmit}
                loading={loading}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {tabsList.includes("eligibility_i9") && (
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              {renderHeader("Eligibility & I9 forms", "eligibility_i9")}
            </Accordion.Header>
            <Accordion.Body>
              <EligibilityForm
                nextStep={nextStep}
                onboardObject={clientObject}
                eligibilityList={eligibilityList}
                countryList={countryList}
                handleGetEmployeeData={handleGetEmployeeData}
                summaryPage
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
}
export default AccordionComp;
