import React, { useState, useEffect } from "react";
import "./style.scss";
import { Modal, Table } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { postData } from "../../../../api";
import Loader from "../../../../components/Loader";
import SignDocumentDetails from "../SignDocumentDetails";

const EmployeeSignDocuments = ({ nextStep, summaryPage }) => {
  const [loading, setLoading] = useState(false);
  const [signDocumentsList, setSignDocumentsList] = useState([]);
  // const [missingRequirement, setMissingRequirement] = useState([]);
  // const [missingTitle, setMissingTitle] = useState("");
  // const [missingSubTitle, setMissingSubTitle] = useState("");
  const [showSignPage, setShowSignPage] = useState(false);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    getSignDocumentsList();
  }, []);

  const getSignDocumentsList = async () => {
    setLoading(true);
    const url = "gusto-employee-document-verification";
    try {
      const res = await postData(url, {}, {});
      if (res?.status === true) {
        if (res.sign_data.is_company === 1) {
          setSignDocumentsList([
            ...res?.sign_data?.employee,
            ...res?.sign_data?.company,
          ]);
        } else {
          setSignDocumentsList([...res?.sign_data?.employee]);
        }

        // setMissingRequirement(res?.missing_requirement);
        // setMissingTitle(res?.missing_title);
        // setMissingSubTitle(res?.missing_subtitle);
        setLoading(false);
      }
    } catch (err) {}
  };

  const handleDocumentClick = (row) => {
    setShowSignPage(true);
    setRowData(row);
  };
  return (
    <>
      {!loading && (
        <div className="paystub-view">
          {
            signDocumentsList !== undefined &&
              signDocumentsList.length !== 0 && (
                <div className="sign-doc-table">
                  <Table responsive className="mt-4 header_bg_table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {signDocumentsList?.length !== 0 ? (
                      <tbody>
                        {signDocumentsList?.map((row, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <div
                                  className="sign-doc-name"
                                  onClick={
                                    summaryPage
                                      ? null
                                      : () => handleDocumentClick(row)
                                  }
                                >
                                  {row?.title}
                                </div>
                              </td>
                              <td>{row?.description}</td>
                              <td>
                                {!row.requires_signing ? (
                                  <Badge
                                    pill
                                    bg="success"
                                    className="sign-status sign-complete-status"
                                  >
                                    {" "}
                                    Completed{" "}
                                  </Badge>
                                ) : (
                                  <Badge
                                    pill
                                    bg="success"
                                    className="sign-status sign-incomplete-status"
                                  >
                                    {" "}
                                    Incomplete{" "}
                                  </Badge>
                                )}
                              </td>
                              <td className="sign-action-btn-wrapper">
                                <button
                                  onClick={
                                    summaryPage
                                      ? null
                                      : () => handleDocumentClick(row)
                                  }
                                >
                                  {!row.requires_signing
                                    ? "View now"
                                    : "Sign now"}
                                </button>{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="3">
                            <h4 className="no-data mb-0">No data found</h4>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              )
            // : (
            //   <div>
            //     <div className="sign-no-data">
            //       <h4>{missingTitle}</h4>
            //       <div>{missingSubTitle}</div>
            //       <div className="sign-error my-2">
            //         <ul>
            //           {missingRequirement?.map((data, i) => {
            //             return (
            //               <React.Fragment key={i}>
            //                 {data?.link_to === "job" && (
            //                   <li>
            //                     <div
            //                       className="sign-doc-name"
            //                       onClick={() => nextStep("job")}
            //                     >
            //                       {data?.title}
            //                     </div>
            //                   </li>
            //                 )}
            //                 {data?.link_to === "personal_info" && (
            //                   <li>
            //                     <div
            //                       className="sign-doc-name"
            //                       onClick={() => nextStep("personal")}
            //                     >
            //                       {data?.title}
            //                     </div>
            //                   </li>
            //                 )}
            //                 {data?.link_to === "statetax" && (
            //                   <li>
            //                     <div
            //                       className="sign-doc-name"
            //                       onClick={() => nextStep("statetax")}
            //                     >
            //                       {data?.title}
            //                     </div>
            //                   </li>
            //                 )}
            //                 {data?.link_to === "signforms" && (
            //                   <li>
            //                     <div
            //                       className="sign-doc-name"
            //                       onClick={() => nextStep("signforms")}
            //                     >
            //                       {data?.title}
            //                     </div>
            //                   </li>
            //                 )}
            //                 {data?.link_to === "banking" && (
            //                   <li>
            //                     <div
            //                       className="sign-doc-name"
            //                       onClick={() => nextStep("banking")}
            //                     >
            //                       {data?.title}
            //                     </div>
            //                   </li>
            //                 )}
            //                 {data?.link_to === "#" && (
            //                   <li>
            //                     <div className="sign-doc-name same-sign-link">
            //                       {data?.title}
            //                     </div>
            //                   </li>
            //                 )}
            //               </React.Fragment>
            //             );
            //           })}
            //         </ul>
            //       </div>
            //     </div>
            //   </div>
            // )
          }
        </div>
      )}
      {loading && <Loader />}
      {showSignPage && (
        <Modal
          show={showSignPage}
          onHide={() => setShowSignPage(false)}
          className="modal-sign-main-wrapper"
        >
          <SignDocumentDetails
            row={rowData}
            setShowSignPage={setShowSignPage}
            getSignDocumentsList={getSignDocumentsList}
          />
        </Modal>
      )}
    </>
  );
};

export default EmployeeSignDocuments;
