import React, { useState, useEffect } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import "./style.scss";
import Button from "../../components/Form/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  mustUpperCaseLetters,
  mustLowerCaseLetters,
  emptySpace,
  mustSpecialCharacterCheck,
  onlyAlphaNumericWithAmpersandAndHyphenAndSpace,
  autocloseTiming,
} from "../../api/regex";
import HeaderForm from "../../components/HeaderForm";
import { postData } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ClientOnboarding = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [clientObject, setClientObject] = useState({
    first_name: "",
    primary_email: "",
    legal_name: "",
    trade_name: "",
    password: "",
    password_confirmation: "",
    user_id: "",
    payRoll: 0,
  });
  const [errors, setErrors] = useState({});

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  useEffect(() => {
    validateVerficationString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateVerficationString = async () => {
    let data = {
      stage: "verfication",
      verfication: id,
    };
    setLoading(true);
    const res = await postData("create_company", {}, data);
    if (res.status === true) {
      setLoading(false);
      setClientObject((prev) => ({
        ...prev,
        primary_email: res.primary_email,
        first_name: res.first_name,
        legal_name: res.legal_name,
        user_id: res.user_id,
        payRoll: res.payroll,
      }));
    } else {
      navigate("/login");
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const findFormErrors = () => {
    const {
      primary_email,
      legal_name,
      trade_name,
      password,
      password_confirmation,
    } = clientObject;
    const newErrors = {};
    if (!primary_email || primary_email === "")
      newErrors.primary_email = "Please enter a valid email.";
    if (!legal_name || legal_name === "")
      newErrors.legal_name = "Please enter your company name.";
    if (legal_name !== "") {
      if (!legal_name?.match(onlyAlphaNumericWithAmpersandAndHyphenAndSpace)) {
        newErrors.legal_name =
          "Company name consists of alphanumeric characters, hyphen (-), and ampersand (&).";
      } else if (legal_name.length < 4) {
        newErrors.legal_name =
          "The company name should be 4 to 100 characters long.";
      } else if (legal_name.length > 100) {
        newErrors.legal_name =
          "The company name should be 4 to 100 characters long.";
      } else if (!legal_name?.match(emptySpace)) {
        newErrors.legal_name =
          "Company name consists of alphanumeric characters, hyphen (-), and ampersand (&).";
      }
    }
    if (trade_name !== "") {
      if (!trade_name?.match(onlyAlphaNumericWithAmpersandAndHyphenAndSpace)) {
        newErrors.trade_name =
          "Trade name consists of alphanumeric characters, hyphen (-), and ampersand (&).";
      } else if (trade_name.length < 4) {
        newErrors.trade_name =
          "The trade name should be  4 to 100 characters long";
      } else if (trade_name.length > 100) {
        newErrors.trade_name =
          "The trade name should be  4 to 100 characters long";
      } else if (!trade_name?.match(emptySpace)) {
        newErrors.trade_name =
          "Trade name consists of alphanumeric characters, hyphen (-), and ampersand (&).";
      }
    }
    if (!password || password === "")
      newErrors.password = "Please enter your password.";
    else {
      if (
        password !== "" &&
        (!mustSpecialCharacterCheck.test(password) ||
          !mustLowerCaseLetters.test(password) ||
          !mustUpperCaseLetters.test(password) ||
          password.length < 8)
      ) {
        newErrors.password = "invalid password";
      }
    }

    if (!password_confirmation || password_confirmation === "")
      newErrors.password_confirmation = "Please enter your password.";
    else if (password_confirmation.trim() !== password.trim()) {
      newErrors.password_confirmation = `Passwords must be the same.`;
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      companyOnboard();
    }
  };

  const companyOnboard = async () => {
    setLoading(true);
    let url =
      clientObject.payRoll === 0 ? "create_company" : "gusto-create-company";
    let data = {
      stage: "password",
      ...clientObject,
    };
    setLoading(true);
    const res = await postData(url, {}, data);
    if (res.status === true) {
      setLoading(false);
      localStorage.setItem("guhroo_token", res.token);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      if (res.auto_url) {
        window.open(res.auto_url, "_self");
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderForm />

      <div className="boarding-layout">
        {!loading && (
          <div className="create-board-cus-wrapper">
            <div className="create-board ">
              <div className="board-title-header-wrapper">
                <h5>Please complete your company account setup</h5>
                <p>Your user name is {clientObject?.first_name} </p>
              </div>
              <div className="board-right">
                <h5 className="mb-3">Create new company</h5>

                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="custom-form"
                >
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="admin">
                        <Form.Label>
                          Admin name <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter admin name"
                          value={clientObject.first_name}
                          onChange={handleInput}
                          isInvalid={!!errors.first_name}
                          name="first_name"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.first_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="primary_email">
                        <Form.Label>
                          Email <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={clientObject.primary_email}
                          onChange={handleInput}
                          isInvalid={!!errors.primary_email}
                          name="primary_email"
                          required
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.primary_email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="company">
                        <Form.Label>
                          Company name <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter company name"
                          value={clientObject.legal_name}
                          onChange={handleInput}
                          isInvalid={!!errors.legal_name}
                          name="legal_name"
                          minLength="4"
                          maxLength="100"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.legal_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="trade">
                        <Form.Label>Trade name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter trade name"
                          value={clientObject.trade_name}
                          onChange={handleInput}
                          isInvalid={!!errors.trade_name}
                          minLength="4"
                          maxLength="100"
                          name="trade_name"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.trade_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>
                          Password <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <InputGroup className="toggle-password">
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter password"
                            name="password"
                            value={clientObject.password}
                            onChange={handleInput}
                            isInvalid={!!errors.password}
                            required
                          />
                          <InputGroup.Text onClick={togglePasswordVisiblity}>
                            {passwordShown ? <FiEye /> : <FiEyeOff />}
                          </InputGroup.Text>
                          {validated && errors.password && (
                            <div className="err-feedback">
                              {errors.password === "invalid password" ? (
                                <div className="pass-invalid">
                                  Password should contain:
                                  <li> 8 or more characters</li>
                                  <li>At least one uppercase letter</li>
                                  <li>At least one lowercase letter</li>
                                  <li>At least one special characters</li>
                                </div>
                              ) : (
                                errors.password
                              )}
                            </div>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="password_confirmation"
                      >
                        <Form.Label>
                          Confirm password <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <InputGroup className="toggle-password">
                          <Form.Control
                            type={confirmPasswordShown ? "text" : "password"}
                            name="password_confirmation"
                            placeholder="Enter confirm password"
                            onChange={handleInput}
                            value={clientObject.password_confirmation}
                            isInvalid={!!errors.password_confirmation}
                            required
                          />
                          <InputGroup.Text
                            onClick={toggleConfirmPasswordVisiblity}
                          >
                            {confirmPasswordShown ? <FiEye /> : <FiEyeOff />}
                          </InputGroup.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.password_confirmation}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="text-end mt-4">
                    <Button
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      className="btn-primary text-white create-btn"
                    >
                      Create account
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        {loading && <Loader />}
      </div>
    </>
  );
};

export default ClientOnboarding;
