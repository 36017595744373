import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  emailRegx,
  mustNumbers,
  mustNumberswithPhone,
  emptySpace,
  ssnRegex,
  onlyCharacterWithSpace,
  onlyCharacterWithSpaceAndComma,
  autocloseTiming,
  characterWithCommaDotHyphenAndApos,
} from "../../../api/regex";
import {
  postData,
  socialSecurityNumberFormat,
  phoneNumberFormat,
  getData,
} from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import ClientSuccessModal from "./ClientSuccessModal";
import NoSignatory from "./noSignatory";

const AddSignatory = ({
  nextStep,
  onboardObject,
  stateList,
  titleList,
  summaryPage,
  getClientStep,
  setStep,
  step,
}) => {
  const focusDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [radioValidated, setRadioValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientObject, setClientObject] = useState({
    primary_email: "",
    first_name: "",
    last_name: "",
    username: "",
    title: "",
    phone_number: "",
    birthday: "",
    str_soc_sec_num: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    preferred_name: "",
    is_signatory: null,
    salary: "",
    annual_salary: "",
    hourly_rate: "",
    weekly_rate: "",
    compensation_name: "",
    skip_gusto: 0,
    emp_status: "",
    flsa: "",
  });
  const [errors, setErrors] = useState({});
  const [stateValue, setStateValue] = useState();
  const [dateInputs, setDateInputs] = useState({
    birthday: null,
  });
  const [show, setShow] = useState(false);
  const [autoUrl, setAutoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const currentDate = new Date();
  const [clientNoObject, setClientNoObject] = useState({
    primary_email: "",
    first_name: "",
    username: "",
    title: "",
    annual_salary: "",
    hourly_rate: "",
    weekly_rate: "",
    compensation_name: "",
    skip_gusto: null,
    emp_status: "",
    flsa: "",
    salary: "",
    acknowledge: false,
    is_signatory: null,
    is_Payroll: null,
  });
  const [noErrors, setNoErrors] = useState({});
  const handleClose = () => setShow(false);
  const [getSalaryData, setGetSalaryData] = useState({});
  const [signatoryError, setSignatoryError] = useState("");
  const [apiLoaded, setApiLoaded] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (onboardObject?.user_info) {
        setClientObject((prevState) => ({
          ...prevState,
          primary_email: onboardObject?.user_info?.email,
          username: onboardObject?.user_info?.username,
          title:
            onboardObject?.user_info?.title === ""
              ? titleList[0]?.name
              : onboardObject?.user_info?.title,
        }));
      }
    }, 2000); // 2-second delay

    return () => clearTimeout(timeoutId);
  }, [onboardObject, titleList]);
  useEffect(() => {
    if (!onboardObject?.add_signatory) {
      const sanitizeValue = (value) =>
        value === null || value === undefined ? "" : value;

      setClientObject((prevState) => ({
        ...prevState,
        first_name: sanitizeValue(onboardObject?.user_info?.first_name),
        last_name: sanitizeValue(onboardObject?.user_info?.last_name),
      }));
    }
  }, [onboardObject]);
  useEffect(() => {
    if (
      apiLoaded &&
      onboardObject?.add_signatory &&
      onboardObject?.add_signatory?.is_signatory === 1
    ) {
      const sanitizeValue = (value) =>
        value === null || value === undefined ? "" : value;

      setClientObject((prevState) => ({
        ...prevState,
        first_name: sanitizeValue(onboardObject?.add_signatory?.first_name),
        last_name: sanitizeValue(onboardObject?.add_signatory?.last_name),
        phone_number: sanitizeValue(onboardObject?.add_signatory?.phone),
        birthday: sanitizeValue(onboardObject?.add_signatory?.birthday),
        str_soc_sec_num: sanitizeValue(onboardObject?.add_signatory?.ssn),
        address: sanitizeValue(
          onboardObject?.add_signatory?.home_address?.street_1
        ),
        address2: sanitizeValue(
          onboardObject?.add_signatory?.home_address?.street_2
        ),
        city: sanitizeValue(onboardObject?.add_signatory?.home_address?.city),
        state: sanitizeValue(onboardObject?.add_signatory?.home_address?.state),
        zip: sanitizeValue(onboardObject?.add_signatory?.home_address?.zip),
        preferred_name: sanitizeValue(
          onboardObject?.add_signatory?.preferred_name
        ),
        is_signatory: sanitizeValue(onboardObject?.add_signatory?.is_signatory),
        salary: sanitizeValue(onboardObject?.add_signatory?.salary.toString()),
        compensation_name: sanitizeValue(
          onboardObject?.add_signatory?.compensation
        ),
        emp_status: sanitizeValue(onboardObject?.add_signatory?.emp_status),
        flsa: sanitizeValue(onboardObject?.add_signatory?.flsa_classification),
        acknowledge: true,
        is_Payroll: sanitizeValue(onboardObject?.add_signatory?.skip_gusto),
      }));

      let data = {
        label: sanitizeValue(onboardObject?.add_signatory?.home_address?.state),
        value: sanitizeValue(onboardObject?.add_signatory?.home_address?.state),
      };
      setStateValue(data);

      const birthdayDate = onboardObject?.add_signatory?.birthday
        ? new Date(onboardObject?.add_signatory?.birthday)
        : ""; // Set empty string if birthday is null
      setDateInputs((prev) => ({ ...prev, birthday: birthdayDate }));
    } else {
      setClientObject((prevState) => ({
        ...prevState,
        is_signatory: onboardObject?.add_signatory?.is_signatory,
      }));
    }
  }, [apiLoaded, onboardObject]);

  useEffect(() => {
    if (
      apiLoaded &&
      onboardObject?.add_signatory &&
      onboardObject?.add_signatory?.is_signatory === 2
    ) {
      setClientNoObject((prevState) => ({
        ...prevState,
        primary_email: onboardObject?.add_signatory?.email,
        first_name: onboardObject?.add_signatory?.first_name,
        username: onboardObject?.add_signatory?.username,
        title: onboardObject?.add_signatory?.title,
        salary: onboardObject?.add_signatory?.salary?.toString() ?? "",
        compensation_name: onboardObject?.add_signatory?.compensation,
        emp_status: onboardObject?.add_signatory?.emp_status,
        flsa: onboardObject?.add_signatory?.flsa_classification,
        acknowledge: true,
        is_signatory: onboardObject?.add_signatory?.is_signatory,
        is_Payroll: onboardObject?.add_signatory?.skip_gusto,
        skip_gusto: onboardObject?.add_signatory?.skip_gusto,
      }));
    }
  }, [apiLoaded, onboardObject]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };
  const handleNoInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientNoObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!noErrors[name])
      setNoErrors({
        ...noErrors,
        [name]: null,
      });
  };

  const handlePhoneFormatChange = (e) => {
    const name = e.target.name;
    const rawValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setClientObject((prev) => ({
      ...prev,
      [name]: rawValue, // Save the clean number
    }));
  };

  const handlePhoneNumberKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ""); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 10;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item.name,
          label: item.name,
          name: item.name,
        });
      });
    }
    return finalArr;
  };

  const findFormErrors = () => {
    const {
      primary_email,
      first_name,
      username,
      title,
      last_name,
      phone_number,
      str_soc_sec_num,
      birthday,
      address,
      city,
      zip,
      preferred_name,
      acknowledge,
      state,
      annual_salary,
      hourly_rate,
      weekly_rate,
      compensation_name,
      emp_status,
      flsa,
      salary,
    } = clientObject;
    const newErrors = {};
    let newSocialNumber = str_soc_sec_num?.replaceAll("-", "");
    if (!primary_email || primary_email === "")
      newErrors.primary_email = "Please enter a valid email.";
    else if (!primary_email?.match(emailRegx)) {
      newErrors.primary_email = "Please enter a valid email address.";
    }
    if (!first_name || first_name === "")
      newErrors.first_name = "Please enter your first name.";
    else if (first_name.length < 3) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (first_name.length > 70) {
      newErrors.first_name =
        "The first name should be  3 to 70 characters long.";
    } else if (!first_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.first_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    }
    if (preferred_name) {
      if (preferred_name !== "" && preferred_name !== null) {
        if (preferred_name?.length < 3) {
          newErrors.preferred_name =
            "The preferred name should be  3 to 70 characters long.";
        } else if (preferred_name.length > 70) {
          newErrors.preferred_name =
            "The preferred name should be  3 to 70 characters long.";
        } else if (!preferred_name?.match(characterWithCommaDotHyphenAndApos)) {
          newErrors.preferred_name =
            "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
        }
      }
    }
    if (!last_name || last_name === "")
      newErrors.last_name = "Please enter your last name.";
    // else if (last_name.length > 1) {
    //   newErrors.last_name = "Please enter your last name.";
    // }
    // else if (last_name.length > 70) {
    //   newErrors.last_name = "The last name should be  3 to 70 characters long.";
    // }
    else if (!last_name?.match(emptySpace)) {
      newErrors.last_name = "Please enter your last name.";
    } else if (!last_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.last_name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    }

    if (!username || username === "")
      newErrors.username = "Please enter your user name.";
    else if (username.length < 3) {
      newErrors.username = "The user name should be  3 to 255 characters long.";
    } else if (username.length > 255) {
      newErrors.username = "The user name should be  3 to 255 characters long.";
    }

    if (!title || title === "") newErrors.title = "Please enter your title.";
    if (!phone_number || phone_number === "") {
      newErrors.phone_number = "Please enter your phone number.";
    }
    if (phone_number !== "" && phone_number !== null) {
      const Phone_numbers_Only = phone_number?.replace(/\D/g, "");
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.phone_number = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.phone_number = "Please enter a 10 digit phone number";
      }
    }
    if (newSocialNumber === "" || newSocialNumber === null) {
      newErrors.str_soc_sec_num = "Please enter your social security number.";
    }
    if (newSocialNumber !== "" && newSocialNumber !== null) {
      if (!newSocialNumber || newSocialNumber === "") {
        newErrors.str_soc_sec_num = "Please enter your social security number.";
      }
      if (!newSocialNumber.toString()?.match(mustNumbers)) {
        newErrors.str_soc_sec_num =
          "The social security number should be numeric.";
      } else if (newSocialNumber.length !== 9) {
        newErrors.str_soc_sec_num =
          "The social security number should be 9 characters.";
      } else if (!str_soc_sec_num.toString()?.match(ssnRegex)) {
        newErrors.str_soc_sec_num =
          "Please enter a valid  social security number.";
      }
    }
    //

    if (!birthday || birthday === "" || birthday === null)
      newErrors.birthday = "Please enter your date of birth.";
    if (birthday !== "" && birthday !== null) {
      if (
        currentDate.getFullYear() - new Date(birthday).getFullYear() < 13 ||
        (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
          currentDate.getMonth() < new Date(birthday).getMonth()) ||
        (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 &&
          currentDate.getMonth() === new Date(birthday).getMonth() &&
          currentDate.getDate() < new Date(birthday).getDate())
      ) {
        newErrors.birthday = "The age must be at least 13 years old.";
      }
    }
    if (!address || address === "") {
      newErrors.address = "Please enter street 1.";
    }
    if (address !== "" && address !== null) {
      if (address.length < 5) {
        newErrors.address =
          "The street name should be  5 to 250 characters long.";
      } else if (address.length > 250) {
        newErrors.address =
          "The street name should be  5 to 250 characters long.";
      }
    }
    // if (address2 !== "") {
    //   if (address2.length < 5) {
    //     newErrors.address2 =
    //       "The street name should be  5 to 250 characters long.";
    //   } else if (address2.length > 250) {
    //     newErrors.address2 =
    //       "The street name should be  5 to 250 characters long.";
    //   } else if (!address2?.match(emptySpace)) {
    //     newErrors.address2 =
    //       "The street name should be  5 to 250 characters long.";
    //   }
    // }
    if (!city || city === "") {
      newErrors.city = "Please enter your city.";
    }
    if (city !== "" && city !== null) {
      if (!city?.match(onlyCharacterWithSpaceAndComma)) {
        newErrors.city = "The city name should be alphabetic.";
      } else if (city.length < 4) {
        newErrors.city = "The city name should be  4 to 20 characters long.";
      } else if (city.length > 20) {
        newErrors.city = "The city name should be  4 to 20 characters long.";
      }
    }
    if (!state || state === "") newErrors.state = "Please enter your state.";
    if (!zip || zip === "") {
      newErrors.zip = "Please enter your zip.";
    }
    if (zip !== "" && zip !== null) {
      if (!zip.toString()?.match(mustNumbers)) {
        newErrors.zip = "The zip should be numeric.";
      } else if (zip.length !== 5) {
        newErrors.zip = "The zip code should be 5 digit.";
      }
    }
    if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";

    if (
      clientObject.is_signatory &&
      (clientObject.skip_gusto === "1" || clientObject.skip_gusto === 1)
    ) {
      if (!compensation_name || compensation_name === "") {
        newErrors.compensation_name = "Please select compensation type";
      } else {
        // Validate annual_salary
        if (
          compensation_name === "Annual" &&
          (!annual_salary || annual_salary === "")
        ) {
          newErrors.annual_salary = "Please enter the annual salary.";
        } else if (compensation_name === "Annual" && isNaN(annual_salary)) {
          newErrors.annual_salary = "The annual salary must be numeric.";
        }

        // Validate hourly_rate
        if (
          compensation_name === "Hourly" &&
          (!hourly_rate || hourly_rate === "")
        ) {
          newErrors.hourly_rate = "Please enter the hourly rate.";
        } else if (compensation_name === "Hourly" && isNaN(hourly_rate)) {
          newErrors.hourly_rate = "The hourly rate must be numeric.";
        }

        // Validate weekly_rate
        if (
          compensation_name === "Weekly" &&
          (!weekly_rate || weekly_rate === "")
        ) {
          newErrors.weekly_rate = "Please enter the weekly rate.";
        } else if (compensation_name === "Weekly" && isNaN(weekly_rate)) {
          newErrors.weekly_rate = "The weekly rate must be numeric.";
        }
      }

      if (!emp_status || emp_status === "" || emp_status === null) {
        newErrors.emp_status = "Please select employment type";
      }
      if (!flsa || flsa === "" || flsa === null) {
        newErrors.flsa = "Please select flsa classification.";
      }
      if (salary === "" || salary === null) {
        newErrors.salary = "Please enter salary.";
      }
    }

    return newErrors;
  };
  const noFormErrors = () => {
    const {
      primary_email,
      first_name,
      username,
      title,
      acknowledge,
      annual_salary,
      hourly_rate,
      weekly_rate,
      compensation_name,
      emp_status,
      flsa,
      salary,
      is_Payroll,
    } = clientNoObject;
    const newErrors = {};
    if (!primary_email || primary_email === "") {
      newErrors.email = "Please enter a valid email.";
    } else if (!primary_email?.match(emailRegx)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!first_name || first_name === "") {
      newErrors.name = "Please enter your name.";
    } else if (first_name.length < 3) {
      newErrors.first_name = "The name should be  3 to 70 characters long.";
    } else if (first_name.length > 70) {
      newErrors.name = "The name should be  3 to 70 characters long.";
    } else if (!first_name?.match(characterWithCommaDotHyphenAndApos)) {
      newErrors.name =
        "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
    }
    if (!username || username === "")
      newErrors.username = "Please enter your user name.";
    else if (username.length < 3) {
      newErrors.username = "The user name should be  3 to 255 characters long.";
    } else if (username.length > 255) {
      newErrors.username = "The user name should be  3 to 255 characters long.";
    }
    if (!title || title === "") newErrors.title = "Please enter your title.";
    if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";

    // if (clientNoObject.is_signatory) {
    if (clientNoObject.is_Payroll === "1" || clientNoObject.is_Payroll === 1) {
      if (!compensation_name || compensation_name === "") {
        newErrors.compensation_name = "Please select a compensation type";
      } else {
        // Validate annual_salary
        if (
          compensation_name === "Annual" &&
          (!annual_salary || annual_salary === "")
        ) {
          newErrors.annual_salary = "Please enter the annual salary.";
        } else if (compensation_name === "Annual" && isNaN(annual_salary)) {
          newErrors.annual_salary = "The annual salary must be numeric.";
        }

        // Validate hourly_rate
        if (
          compensation_name === "Hourly" &&
          (!hourly_rate || hourly_rate === "")
        ) {
          newErrors.hourly_rate = "Please enter the hourly rate.";
        } else if (compensation_name === "Hourly" && isNaN(hourly_rate)) {
          newErrors.hourly_rate = "The hourly rate must be numeric.";
        }

        // Validate weekly_rate
        if (
          compensation_name === "Weekly" &&
          (!weekly_rate || weekly_rate === "")
        ) {
          newErrors.weekly_rate = "Please enter the weekly rate.";
        } else if (compensation_name === "Weekly" && isNaN(weekly_rate)) {
          newErrors.weekly_rate = "The weekly rate must be numeric.";
        }
      }

      // Validate emp_status
      if (!emp_status || emp_status === "" || emp_status === null) {
        newErrors.emp_status = "Please select employment type";
      }

      // Validate flsa
      if (!flsa || flsa === "" || flsa === null) {
        newErrors.flsa = "Please select flsa classification.";
      }
      if (salary === "" || salary === null) {
        newErrors.salary = "Please enter salary.";
      }
    }
    // }
    if (
      (clientObject.skip_gusto === 0 || clientObject.skip_gusto === "0") &&
      (clientNoObject.skip_gusto === "2" ||
        clientNoObject.skip_gusto === 2 ||
        clientNoObject.skip_gusto === null)
    ) {
      if (is_Payroll === null || is_Payroll === "") {
        newErrors.is_Payroll = " Please select payroll type.";
      }
    }

    return newErrors;
  };
  const findRadioFormErrors = () => {
    const { is_signatory, acknowledge } = clientObject;
    const newErrors = {};
    if (
      !is_signatory ||
      is_signatory === "" ||
      is_signatory === null ||
      is_signatory === undefined
    )
      newErrors.is_signatory = "Please select the signatory.";
    if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      federalTaxSetup();
    }
  };
  const handleNoSubmit = (event) => {
    event.preventDefault();
    const newErrors = noFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setNoErrors(newErrors);
    } else {
      noFederalTaxSetup();
    }
  };
  const federalTaxSetup = async () => {
    setLoading(true);

    let data = {
      is_signatory: 0,
      ...clientObject,
      skip_gusto: clientObject.skip_gusto === "1" ? 1 : 2,
      is_Payroll: clientObject.skip_gusto === "1" ? 1 : 2,
    };
    data.is_signatory =
      clientObject.is_signatory === "Yes" || clientObject.is_signatory === 1
        ? 1
        : 2;
    const res = await postData("add-gusto-signatory", {}, data);
    if (res.status === true) {
      setLoading(false);
      setStep(res.next_steps);
      nextStep(res.next_steps);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      getClientStep();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  const noFederalTaxSetup = async () => {
    setLoading(true);
    let data = {
      is_signatory: 0,
      ...clientNoObject,
      skip_gusto: clientNoObject.is_Payroll === "1" ? 1 : 2,
      is_Payroll: clientNoObject.is_Payroll === "1" ? 1 : 2,
    };
    data.is_signatory =
      clientObject.is_signatory === "Yes" || clientObject.is_signatory === 1
        ? 1
        : 2;
    const res = await postData("add-gusto-signatory", {}, data);
    if (res.status === true) {
      setLoading(false);
      setStep(res.next_steps);
      nextStep(res.next_steps);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      getClientStep();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleStateOnChange = (data) => {
    setStateValue(data);
    setClientObject((prev) => ({
      ...prev,
      state: data.value,
    }));
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      let val = formatDates(date);
      setClientObject((prev) => ({
        ...prev,
        [name]: val,
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          [name]: null,
        });
    }
  };

  const handleFocusDate = () => {
    const datepickerElement = focusDate.current;
    datepickerElement.setFocus(true);
  };

  const handleFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = socialSecurityNumberFormat(e.target.value);
    setClientObject((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ""); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 9;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };

  const handleRadioChange = (event) => {
    setClientObject((prev) => ({
      ...prev,
      is_signatory: event.target.value,
      skip_gusto:
        event.target.value === "Yes" || event.target.value === 1 ? 1 : 2,
    }));
    setSignatoryError("");
  };
  const handleNoRadioChange = (event) => {
    setClientNoObject((prev) => ({
      ...prev,
      is_Payroll: event.target.value,
    }));
    setNoErrors((prev) => ({
      ...prev,
      is_Payroll: "",
    }));
  };

  const yesSignatory = async () => {
    setLoading(true);
    let data = {
      stage: "add_signatory",
      is_signatory: clientObject.is_signatory === "Yes" ? 1 : 2,
    };
    const res = await postData("client_onboarding_steps", {}, data);
    if (res.status === true) {
      setLoading(false);
      setAutoUrl(res.auto_url);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      if (res.status === true) {
        // setShow(true)
        setAutoUrl(res.auto_url);
        setTitle(res?.title);
        setSubTitle(res?.subtitle);
        // nextStep("all_done")
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
      setShow(false);
    }
  };

  function formRadioSubmit(event) {
    event.preventDefault();
    const newErrors = findRadioFormErrors();
    setRadioValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      yesSignatory();
    }
  }

  const handleCheckAcknowledgeChange = (e) => {
    const name = e.target.name;
    if (e.target.checked === true) {
      setClientObject((prev) => ({
        ...prev,
        acknowledge: true,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          acknowledge: null,
        });
    } else {
      setClientObject((prev) => ({
        ...prev,
        acknowledge: false,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          acknowledge: null,
        });
    }
  };
  const handleCheckNoAcknowledgeChange = (e) => {
    const name = e.target.name;
    if (e.target.checked === true) {
      setClientNoObject((prev) => ({
        ...prev,
        acknowledge: true,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          acknowledge: null,
        });
    } else {
      setClientNoObject((prev) => ({
        ...prev,
        acknowledge: false,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          acknowledge: null,
        });
    }
  };
  const handleGetPersonalDetails = async () => {
    setLoading(true);
    try {
      const res = await postData("add-gusto-signatory-details", {});
      setApiLoaded(true);
      if (
        onboardObject?.add_signatory &&
        onboardObject?.add_signatory?.is_signatory === 1
      ) {
        setClientObject((prev) => ({
          ...prev,
          ...res.data,
          // primary_email: res.data.email,
          // skip_gusto: res.data.skip_gusto,
        }));
      }
      if (
        onboardObject?.add_signatory &&
        onboardObject?.add_signatory?.is_signatory === 2
      ) {
        setClientNoObject((prev) => ({
          ...prev,
          ...res.data,
          // skip_gusto: res.data.skip_gusto,
          // is_Payroll: res.data.skip_gusto,
        }));
      }
      setLoading(false);
    } catch (err) {
      toast.error(err, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetPersonalDetails();
  }, []);
  const handleEmploymentTypeChange = (e) => {
    const { value } = e.target;
    setClientObject({ ...clientObject, emp_status: value });
  };
  const handleInputChange = (value) => {
    setClientObject((prev) => ({
      ...prev,
      salary: value,
    }));
  };
  const handleSalaryTypeChange = (compensationType) => {
    setClientObject((prev) => ({
      ...prev,
      compensation_name: compensationType,
    }));
  };
  const handleNoEmploymentTypeChange = (e) => {
    const { value } = e.target;
    setClientNoObject({ ...clientNoObject, emp_status: value });
  };
  const handleNoInputChange = (value) => {
    setClientNoObject((prev) => ({
      ...prev,
      salary: value,
    }));
  };
  const handleNoSalaryTypeChange = (compensationType) => {
    setClientNoObject((prev) => ({
      ...prev,
      compensation_name: compensationType,
    }));
  };
  const getDataforEmployeeType = async () => {
    setLoading(true);
    try {
      const res = await getData("add-gusto-signatory-data", {});
      if (res.status === true) {
        setLoading(false);
        setGetSalaryData(res.data);
      }
    } catch (err) {
      toast.error(err, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataforEmployeeType();
  }, []);
  const handleFlsaChange = (selectedOption) => {
    setClientObject((prevClientObject) => ({
      ...prevClientObject,
      flsa: selectedOption.value,
    }));
  };
  const handleNoFlsaChange = (selectedOption) => {
    setClientNoObject((prevClientObject) => ({
      ...prevClientObject,
      flsa: selectedOption.value,
    }));
  };
  const handleSignatoryValidation = () => {
    if (
      clientObject.is_signatory === null ||
      clientObject.is_signatory === undefined ||
      clientObject.is_signatory === ""
    ) {
      setSignatoryError("Please select a value");
    }
  };
  const selectedOption = formatSelectOptions(getSalaryData.flsa).find(
    (option) => option.value === clientObject.flsa
  );
  const getCompensationUnit = (compensationName) => {
    switch (compensationName) {
      case "ANNUAL SALARY":
        return "year";
      case "HOURLY RATE":
        return "hour";
      case "WEEKLY RATE":
        return "week";
      default:
        return "";
    }
  };
  return (
    <>
      {!loading && (
        <>
          <div className="assign_signatory_container">
            <h5>
              Assign company signatory <span className="asterisk">*</span>{" "}
            </h5>
            <Form
              noValidate
              validated={radioValidated}
              onSubmit={formRadioSubmit}
              autoComplete="off"
            >
              <Row>
                <Col>
                  <Form.Group
                    className=" is-signatory mb-3"
                    controlId="is_signatory"
                  >
                    <Form.Check
                      type="radio"
                      id={`authorized-radio`}
                      name="Yes"
                      value="Yes"
                      label="Yes, I’m authorized to sign official forms from the company."
                      checked={
                        clientObject.is_signatory === "Yes" ||
                        clientObject.is_signatory === 1
                      }
                      onChange={(e) => handleRadioChange(e)}
                      required
                      disabled={summaryPage}
                    />
                    <Form.Check
                      type="radio"
                      id={`appoint-radio`}
                      name="No"
                      value="No"
                      label="No, I’ll appoint another person to sign official forms"
                      checked={
                        clientObject.is_signatory === "No" ||
                        clientObject.is_signatory === 2
                      }
                      onChange={(e) => handleRadioChange(e)}
                      required
                      disabled={summaryPage}
                    />
                    {radioValidated &&
                      (!clientObject.is_signatory ||
                        clientObject.is_signatory === "" ||
                        clientObject.is_signatory === null ||
                        clientObject.is_signatory === undefined) && (
                        <div className="err-feedback">
                          {errors.is_signatory}
                        </div>
                      )}
                    {signatoryError !== "" ? (
                      <div className="err-feedback">{signatoryError}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              {(clientObject.is_signatory === "No" ||
                clientObject.is_signatory === 2) && (
                <>
                  <NoSignatory
                    validated={validated}
                    noErrors={noErrors}
                    handleNoInput={handleNoInput}
                    clientNoObject={clientNoObject}
                    titleList={titleList}
                    summaryPage={summaryPage}
                    formatSelectOptions={formatSelectOptions}
                    getSalaryData={getSalaryData}
                    handleNoEmploymentTypeChange={handleNoEmploymentTypeChange}
                    handleNoInputChange={handleNoInputChange}
                    handleNoSalaryTypeChange={handleNoSalaryTypeChange}
                    handleNoRadioChange={handleNoRadioChange}
                    handleNoFlsaChange={handleNoFlsaChange}
                  />
                </>
              )}
              {(clientObject.is_signatory === "No" ||
                clientObject.is_signatory === 2) && (
                <>
                  <Row className="check-custom mt-5">
                    <Col md={8} className="align-seld-center">
                      <Form.Group
                        className="bank-check ack-step-last mb-3"
                        controlId="acknowledge"
                      >
                        <Form.Check
                          label={
                            <span>
                              I agree to the
                              <a
                                className="ms-1"
                                href="https://flows.gusto.com/terms"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms of Service
                              </a>
                            </span>
                          }
                          name="acknowledge"
                          type="checkbox"
                          checked={clientNoObject.acknowledge || summaryPage}
                          value="1"
                          onChange={(e) => handleCheckNoAcknowledgeChange(e)}
                          id="acknowledge_check"
                          required
                          // disabled={summaryPage}
                        />
                        {!clientNoObject.acknowledge && (
                          <div className="err-feedback">
                            {noErrors.acknowledge}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    {!summaryPage && (
                      <Col md={4}>
                        <div className="text-end">
                          <Button
                            type="button"
                            className="btn-outline-primary me-2"
                            onClick={() => nextStep("bank_verification")}
                          >
                            Back
                          </Button>
                          <Button
                            type="submit"
                            disabled={loading}
                            loading={loading}
                            onClick={handleNoSubmit}
                            className="btn-primary text-white"
                          >
                            Save & Continue
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </Form>
          </div>
          {(clientObject.is_signatory === "Yes" ||
            clientObject.is_signatory === 1) && (
            <div className="board-right board-tab-sections mt-3">
              <h5 className="mb-3"> Add signatory</h5>

              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                autoComplete="off"
                className="custom-form mb-4"
              >
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="first_name">
                      <Form.Label>
                        First name <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        value={clientObject.first_name}
                        onChange={handleInput}
                        isInvalid={!!errors.first_name}
                        name="first_name"
                        maxLength="70"
                        required
                        disabled={summaryPage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.first_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="preferred_name">
                      <Form.Label>Preferred Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Preferred Name"
                        value={clientObject.preferred_name}
                        onChange={handleInput}
                        isInvalid={!!errors.preferred_name}
                        name="preferred_name"
                        maxLength="70"
                        disabled={summaryPage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.preferred_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>
                        Last name <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        value={clientObject.last_name}
                        onChange={handleInput}
                        isInvalid={!!errors.last_name}
                        name="last_name"
                        required
                        disabled={summaryPage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.last_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="username">
                      <Form.Label>
                        User name <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Create user name"
                        value={clientObject.username}
                        onChange={handleInput}
                        isInvalid={!!errors.username}
                        name="username"
                        maxLength="255"
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="primary_email">
                      <Form.Label>
                        Email <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={clientObject.primary_email}
                        onChange={handleInput}
                        isInvalid={!!errors.primary_email}
                        name="primary_email"
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.primary_email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label>
                        Title <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Select
                        onChange={handleInput}
                        name="title"
                        value={
                          clientObject.title !== ""
                            ? clientObject.title
                            : titleList[0]?.name
                        }
                        required
                        size="lg"
                        disabled
                      >
                        <option value="">Select title</option>
                        {titleList &&
                          titleList?.map((item, index) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="phone_number">
                      <Form.Label>
                        Phone number<span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <InputGroup className="phone-group">
                        <InputGroup.Text>+1</InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="Enter phone number"
                          value={clientObject.phone_number}
                          onChange={handleInput}
                          isInvalid={!!errors.phone_number}
                          name="phone_number"
                          onBlur={handlePhoneFormatChange}
                          onKeyPress={handlePhoneNumberKeyPress}
                          disabled={summaryPage}
                          // maxLength="10"
                          // required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone_number}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group
                      className="position-relative mb-3 personal-date-picker"
                      controlId="birthday"
                    >
                      <Form.Label>
                        Date of birth <span className="asterisk">*</span>
                      </Form.Label>
                      <DatePicker
                        ref={focusDate}
                        className="datepicker-payscheme"
                        selected={
                          dateInputs?.birthday ? dateInputs?.birthday : null
                        }
                        onChange={(date) => selectUploadDt(date, "birthday")}
                        name="birthday"
                        dateFormat="MM-dd-yyyy"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        calendarIcon={true}
                        closeCalendar={true}
                        clearIcon={true}
                        maxDate={new Date()}
                        disabled={summaryPage}
                      />
                      <span
                        className="calendar-icon"
                        onClick={handleFocusDate}
                      ></span>
                      {validated && (
                        <div className="err-feedback">{errors?.birthday}</div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="str_soc_sec_num">
                      <Form.Label>
                        Social security number{" "}
                        <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter social security number"
                        value={clientObject.str_soc_sec_num}
                        onChange={handleInput}
                        isInvalid={!!errors.str_soc_sec_num}
                        name="str_soc_sec_num"
                        // required
                        onBlur={handleFormatChange}
                        onKeyPress={handleKeyPress}
                        disabled={summaryPage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.str_soc_sec_num}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h5 className="mb-3">Signatory home address</h5>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="address">
                      <Form.Label>
                        Street 1 <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter street 1"
                        value={clientObject.address}
                        onChange={handleInput}
                        isInvalid={!!errors.address}
                        name="address"
                        maxLength="250"
                        // required
                        disabled={summaryPage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="address2">
                      <Form.Label>Street 2 (Optional)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter street 2 "
                        value={clientObject.address2}
                        onChange={handleInput}
                        // isInvalid={!!errors.address2}
                        name="address2"
                        maxLength="250"
                        disabled={summaryPage}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errors.address2}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="city">
                      <Form.Label>
                        City <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter city"
                        value={clientObject.city}
                        onChange={handleInput}
                        isInvalid={!!errors.city}
                        name="city"
                        maxLength="20"
                        // required
                        disabled={summaryPage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="state">
                      <Form.Label>
                        State <span className="asterisk">*</span>
                      </Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={formatSelectOptions(stateList)}
                        onChange={(selectedOption) =>
                          handleStateOnChange(selectedOption)
                        }
                        value={stateValue}
                        placeholder="Select state"
                        className="custom-select"
                        isDisabled={summaryPage}
                      />
                    </Form.Group>{" "}
                    {validated && clientObject?.state === "" && (
                      <div className="err-feedback">Please enter state.</div>
                    )}
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="zip">
                      <Form.Label>
                        Zip Code <span className="asterisk">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter zip code"
                        value={clientObject.zip}
                        onChange={handleInput}
                        isInvalid={!!errors.zip}
                        name="zip"
                        // required
                        disabled={summaryPage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zip}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {/* {summaryPage && Object.keys(getSalaryData).length > 0 && (
                  <> */}
                {clientObject?.is_signatory === 1 ||
                clientObject?.is_signatory === "Yes" ? (
                  <>
                    <Row>
                      <div className="onboarding-salary-type-wrapper">
                        <Form.Label>
                          Status <span className="asterisk">*</span>
                        </Form.Label>
                        <div className="onboarding-employment-sub-wrapper">
                          {getSalaryData &&
                            getSalaryData?.status &&
                            Object.keys(getSalaryData?.status)?.map(
                              (type, index) => {
                                const radioId = `employment-type-radio-${index}`;
                                return (
                                  <Form.Check
                                    key={index}
                                    type="radio"
                                    id={radioId}
                                    name="employmentType"
                                    value={type}
                                    label={getSalaryData.status[type]}
                                    checked={clientObject.emp_status === type}
                                    onChange={(e) =>
                                      handleEmploymentTypeChange(e)
                                    }
                                    isInvalid={!!errors.emp_status}
                                    disabled={summaryPage}
                                  />
                                );
                              }
                            )}
                        </div>
                        {validated &&
                          (clientObject?.emp_status === "" ||
                            clientObject?.emp_status === null) && (
                            <div className="err-feedback">
                              Please select employment type
                            </div>
                          )}
                      </div>
                    </Row>
                    <Row>
                      <div className="onboarding-employment-wrapper">
                        <Form.Label>
                          Compensation <span className="asterisk">*</span>
                        </Form.Label>
                        <div className="onboarding-employment-sub-wrapper">
                          {getSalaryData?.compensation?.map((data, index) => {
                            const radioId = `salary-type-${index}`;
                            return (
                              <div
                                className="onboarding-salary-sub-wrapper position-relative"
                                key={radioId}
                              >
                                <Form.Check
                                  type="radio"
                                  id={radioId}
                                  name="salaryType"
                                  value={data.name}
                                  label={
                                    data.name.charAt(0).toUpperCase() +
                                    data.name.slice(1).toLowerCase()
                                  }
                                  onChange={() =>
                                    handleSalaryTypeChange(data.name)
                                  }
                                  checked={
                                    clientObject.compensation_name === data.name
                                  }
                                  disabled={summaryPage}
                                />
                              </div>
                            );
                          })}
                        </div>
                        {validated &&
                          clientObject?.compensation_name === "" && (
                            <div className="err-feedback">
                              Please select compensation type
                            </div>
                          )}
                        <div className="salary-input-wrapper">
                          <Form.Control
                            type="text"
                            placeholder="$ ___,___.__"
                            disabled={
                              clientObject.compensation_name === "" ||
                              clientObject.compensation_name === null ||
                              summaryPage
                            }
                            value={clientObject.salary}
                            onKeyPress={(e) => {
                              if (
                                !/^\d*\.?\d{0,2}$/.test(e.target.value + e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => handleInputChange(e.target.value)}
                          />
                          <span className="salary-unit">
                            /{" "}
                            {getCompensationUnit(
                              clientObject.compensation_name
                            )}
                          </span>
                        </div>{" "}
                        {(clientObject?.salary === "" ||
                          clientObject?.salary === null) && (
                          <div className="err-feedback">{errors.salary}</div>
                        )}
                      </div>
                    </Row>
                    <Row>
                      <div className="onboarding-dropdown-wrapper">
                        {/* <div className="dropdown-wrapper-cus">
                      <Form.Label>
                        Choose pay frequency <span className="asterisk">*</span>
                      </Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={formatSelectOptions(getSalaryData.frequency)}
                        placeholder="Select one"
                        className="custom-select"
                      />
                    </div> */}
                        {summaryPage &&
                        onboardObject.flsa_classification === null ? null : (
                          <div className="dropdown-wrapper-cus">
                            <Form.Label>
                              FLSA classification
                              <span className="asterisk">*</span>
                            </Form.Label>
                            <Select
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              options={formatSelectOptions(getSalaryData.flsa)}
                              placeholder="Select FLSA classification"
                              className="custom-select"
                              onChange={handleFlsaChange}
                              isDisabled={summaryPage}
                              value={selectedOption}
                            />{" "}
                          </div>
                        )}{" "}
                      </div>{" "}
                      {validated &&
                        (clientObject?.flsa === "" ||
                          clientObject?.flsa === null) && (
                          <div className="err-feedback">
                            Please select flsa classification
                          </div>
                        )}
                    </Row>
                  </>
                ) : null}
                {/*  */}
                <>
                  <Row className="check-custom mt-5 ">
                    <Col md={8} className="align-self-center">
                      <Form.Group
                        className="bank-check ack-step-last mb-3"
                        controlId="acknowledge"
                      >
                        <Form.Check
                          label={
                            <span>
                              I agree to the
                              <a
                                className="ms-1"
                                href="https://flows.gusto.com/terms"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms of Service
                              </a>
                            </span>
                          }
                          name="acknowledge"
                          type="checkbox"
                          checked={clientObject.acknowledge || summaryPage}
                          value="1"
                          id="check_acknowledge"
                          onChange={(e) => handleCheckAcknowledgeChange(e)}
                          required
                        />
                        {validated && (
                          <div className="err-feedback">
                            {errors.acknowledge}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    {!summaryPage && (
                      <Col md={4}>
                        <div className="text-end">
                          <Button
                            type="button"
                            className="btn-outline-primary me-2"
                            onClick={() => nextStep("bank_verification")}
                          >
                            Back
                          </Button>
                          <Button
                            type="submit"
                            disabled={loading}
                            loading={loading}
                            className="btn-primary text-white"
                          >
                            Save & Continue
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              </Form>
            </div>
          )}
          {(clientObject.is_signatory === "" || !clientObject.is_signatory) && (
            <>
              {!summaryPage && (
                <Col md={12}>
                  <div className="text-end">
                    <Button
                      type="button"
                      className="btn-outline-primary me-2"
                      onClick={() => nextStep("bank_verification")}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      onClick={handleSignatoryValidation}
                      className="btn-primary text-white"
                    >
                      Save & Continue
                    </Button>
                  </div>
                </Col>
              )}
            </>
          )}
          {
            <ClientSuccessModal
              show={show}
              onClose={handleClose}
              autoUrl={autoUrl}
              title={title}
              subtitle={subtitle}
            />
          }
        </>
      )}
      {loading && <Loader />}
    </>
  );
};

export default AddSignatory;
