import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { FiHelpCircle } from "react-icons/fi";
import { mustNumberswithPhone } from "../../../api/regex";
import Routing from "../../../assets/images/routing.svg";
import Account from "../../../assets/images/account.svg";
import Loader from "../../../components/Loader";
import PayrollScheme from "./PayrollScheme";

const AddBankInfo = (props) => {
  const {
    nextStep,
    onboardObject,
    payFrequencyList,
    firstPayList,
    secondPayList,
    summaryPage,
    getClientStep,
  } = props;
  const [bankValidated, setBankValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientObject, setClientObject] = useState({
    bank_routing_number: "",
    bank_account_number: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (onboardObject?.bank_info) {
      setClientObject(onboardObject?.bank_info);
    }
  }, [onboardObject]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const renderTooltip = (
    <div>
      <h6 className="py-1">Routing number</h6>
      <div>
        <img src={Routing} alt="route img" />
      </div>
    </div>
  );

  const renderAccountTooltip = (
    <div>
      <h6 className="py-1"> Account number</h6>
      <div>
        <img src={Account} alt="route img" />
      </div>
    </div>
  );

  const isValidRoutingNumber = (n) => {
    if (n.length !== 9 || isNaN(n)) {
      return false;
    } else {
      let digits = n.split("");
      var sum =
        digits[0] * 3 +
        digits[1] * 7 +
        digits[2] * 1 +
        digits[3] * 3 +
        digits[4] * 7 +
        digits[5] * 1 +
        digits[6] * 3 +
        digits[7] * 7 +
        digits[8] * 1;
      return sum % 10 === 0;
    }
  };
  const findFormErrors = () => {
    const { bank_routing_number, bank_account_number } = clientObject;
    const newErrors = {};
    if (!bank_routing_number || bank_routing_number === "")
      newErrors.bank_routing_number = "Please enter routing number.";
    else if (!bank_routing_number?.match(mustNumberswithPhone)) {
      newErrors.bank_routing_number = "The routing number should be numeric.";
    } else if (bank_routing_number.length !== 9) {
      newErrors.bank_routing_number =
        "The routing number should be 9 characters.";
    } else if (isValidRoutingNumber(bank_routing_number) === false) {
      newErrors.bank_routing_number = "Please enter a valid routing number.";
    }
    if (!bank_account_number || bank_account_number === "")
      newErrors.bank_account_number = "Please enter your account number.";
    else if (!bank_account_number?.match(mustNumberswithPhone)) {
      newErrors.bank_account_number = "The account number should be numeric.";
    } else if (bank_account_number.length < 5) {
      newErrors.bank_account_number =
        "The account number should be  5 to 25 characters long.";
    } else if (bank_account_number.length > 25) {
      newErrors.bank_account_number =
        "The account number should be  5 to 25 characters long.";
    }
    return newErrors;
  };
  return (
    <>
      {!loading && (
        <div className="board-right board-tab-sections payroll-client">
          <h5 className="mb-3"> Add bank info</h5>
          <Form
            noValidate
            bankValidated={bankValidated}
            autoComplete="off"
            className="custom-form"
          >
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="bank_routing_number">
                  <Form.Label className="me-2">
                    Routing number (9 digits){" "}
                    <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Tooltip
                    placement="right"
                    overlay={renderTooltip}
                    overlayClassName="bank-tooltip"
                  >
                    <FiHelpCircle className="help-circle" />
                  </Tooltip>
                  <Form.Control
                    type="text"
                    placeholder="Enter routing number (9 digits)"
                    value={clientObject.bank_routing_number}
                    onChange={handleInput}
                    isInvalid={!!errors.bank_routing_number}
                    name="bank_routing_number"
                    required
                    maxLength="9"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bank_routing_number}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="bank_account_number">
                  <Form.Label className="me-2">
                    Account number <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Tooltip
                    placement="right"
                    overlay={renderAccountTooltip}
                    overlayClassName="bank-tooltip"
                  >
                    <FiHelpCircle className="help-circle" />
                  </Tooltip>
                  <Form.Control
                    type="text"
                    placeholder="Enter account number"
                    value={clientObject.bank_account_number}
                    onChange={handleInput}
                    isInvalid={!!errors.bank_account_number}
                    name="bank_account_number"
                    required
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bank_account_number}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="check-custom mt-5">
              <PayrollScheme
                nextStep={nextStep}
                onboardObject={onboardObject}
                payFrequencyList={payFrequencyList}
                firstPayList={firstPayList}
                secondPayList={secondPayList}
                bankInfoClientObject={clientObject}
                bankErrors={findFormErrors}
                setBankValidated={setBankValidated}
                setBankErrors={setErrors}
                setLoading={setLoading}
                loading={loading}
                summaryPage={summaryPage}
                getClientStep={getClientStep}
              />
            </Row>
          </Form>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default AddBankInfo;
